<div class="content-heading">
    <div class="header-container">
        <h3>Traspaso de Equipo entre Rutas</h3>
        <small>Traspaso de equipo completo entre rutas de un mismo centro de ventas</small>
    </div>
</div>

<div class="container-fluid">
  <div class="form-container">
    <div class="row">
        <div class="form-group col col-md-2">   
            <label class="form-label">Fecha</label>
            <input class="form-control" bsDatepicker [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }" name="LabelDate"
                   [(ngModel)]="selectedDate" placeholder="Fecha" disabled />            
        </div>
        <div class="form-group col col-md-2">
            <label class="form-label">Organizaci&oacute;n</label>
            <select title="Org" class="form-control" [(ngModel)]="orgId" (ngModelChange)="changeFilterOrg($event)" required>
                <option [value]="0" selected>TODOS</option>
                <option *ngFor="let unit of org" [value]="unit.UnitId">{{unit.OrgName}}</option>
            </select>
        </div>
        <div class="form-group col col-md-4">
            <label class="form-label">Centro de ventas</label>
            <ceq-unit-autocomplete [loading]="working"
                [unitOrgId]="orgId"
                [listType]="'CEVE'"
                (onUnitClear)="clearOutputNode()"
                (onUnitSelected)="setOutputNode($event)">
            </ceq-unit-autocomplete>
        </div>
    </div>
    <div class="row">
    </div>
  </div>
</div>
<!--<app-loading *ngIf="loading"></app-loading>-->
<div class="container-fluid">
    <div class="form-container">
        <div class="row">
            <div class="col-md-3 center cell-header background-nav-color">
                <label class="form-label">MOVIMIENTO</label>
            </div>
            <div class="col-md-3 center cell-header background-nav-color">
                <label class="form-label">RUTAS ORIGEN</label>
            </div>
            <div class="col-md-3 center cell-header background-nav-color">
                <label class="form-label">RUTAS DESTINO</label>
            </div>
            <div class="col-md-3 center cell-header background-nav-color">
                <label class="form-label">AGREGAR TRASPASO</label>
            </div>            
        </div>
        <div class="row">
            <div class="col-md-3 center" style="margin-top: 1em;">
                <label class="form-label">Movimiento {{move + 1}}</label>
            </div>
            <div class="col-md-3 center">
                <select title="Route" class="form-control" name="origin" [(ngModel)]="originRoute" (change)="cmbOriginRouteChange()" [disabled]="noRoute">
                    <option value="0" selected disabled>Selecciona ruta</option>
                    <option *ngFor="let origin of routesOrigin" [value]="origin.RouteId">
                        {{origin.RouteCode}} - {{origin.RouteName}}
                    </option>
                </select>
            </div>
            <div class="col-md-3 center">
                <select title="Route" class="form-control" name="destiny" [(ngModel)]="destinyRoute" (change)="cmbDestinyRouteChange()" [disabled]="noRoute">
                    <option value="0" selected disabled>Selecciona ruta</option>
                    <option *ngFor="let destiny of routesTarget" [value]="destiny.RouteId">
                        {{destiny.RouteCode}} - {{destiny.RouteName}}
                    </option>
                </select>                
            </div>
            <div class="col-md-3 center">
                <button title="Agregar" type="button" class="btn btn-outline-primary btn-sm" style="border-radius: 50%;" (click)="addTransfer()">
                    <i class="fa fa-plus" aria-hidden="true" style="margin-top: .3em;"></i>
                </button>
            </div>
        </div>
        <br>
        <div class="row" *ngIf="balances.length > 0">
            <p><b>EQUIPO A TRASPASAR</b></p>
        </div>
        <div class="row" *ngIf="balances.length > 0">
            <div class="d-flex">
                <div *ngFor="let eq of balances">
                    <div class="column_width center-data background-nav-color"> {{eq.Container}}</div>
                    <div class="column_width center-data"> {{eq.Balance}}</div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="balances.length > 0"><br></div>        
        <div class="row">
            <p><b>TRASPASOS A REALIZAR</b></p>
        </div>
        <div class="row">
            <div class="col-md-12 center">
                <div class="col-md-9 col-xs-9 div-item-center">
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                                <tr class="background-sidebar-color">
                                    <th style="text-align: center;">Movimiento</th>
                                    <th>Ruta Origen</th>
                                    <th>Ruta Destino</th>
                                    <th style="text-align: center;">Quitar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let transfer of transfers; let i = index">
                                    <td style="text-align: center;">Movimiento {{transfer.MovementId}}</td>
                                    <td>{{getRouteName(transfer.OriginRouteId)}}</td>
                                    <td>{{getRouteName(transfer.DestinyRouteId)}}</td>
                                    <td style="text-align: center;">
                                        <button title="Quitar" type="button" class="btn btn-outline-primary btn-sm" style="border-radius: 50%;" (click)="delete(transfer.MovementId)">
                                            <i class="fa fa-trash" aria-hidden="true" style="margin-top: .3em;"></i>
                                        </button>                        
                                    </td>
                                </tr>
                            </tbody>
                        </table>    
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-12 center">
                <button title="Realizar Traspasos" type="button" class="btn btn-outline-success mr-1 btn-labeled mb-2" (click)="save()" [disabled]="transfers.length == 0">
                    <span class="btn-label">
                        <i class="fa fa-floppy-o" aria-hidden="true" *ngIf="!saving"></i>
                        <i class="fa fa-circle-notch fa-spin"  aria-hidden="true" *ngIf="saving"></i>
                    </span>
                    <span>Realizar Traspasos</span>
                </button>
            </div>
        </div>
    </div>
</div>