import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { List } from 'lodash';
import { GenericService } from '../../../common/services/GenericServices';

@Injectable({
  providedIn: 'root'
})
export class PaybillService {

  constructor(private genericServ: GenericService) { }

  /**
   * Get paybill list
   * @param unitId 
   * @param creationDate 
   * @param salesman 
   * @param reference 
   * @param selectedStatus 
   * @returns 
   */
  getPaybillList(unitId: number = 0, creationDate: string = null, endDate: string = null, salesman: string = '', reference: string = "", selectedStatus: string = "", channelId: number = 0) {
    let url = `Paybill/list`;
    let parameters;

    if (reference != '') { parameters == undefined ? parameters = { reference: reference } : parameters.reference = reference; }
    if (unitId != 0) { parameters == undefined ? parameters = { unitId: unitId } : parameters.unitId = unitId; }
    if (creationDate != '') { parameters == undefined ? parameters = { creationDate: creationDate } : parameters.creationDate = creationDate; }
    if (endDate != '') { parameters == undefined ? parameters = { endDate: endDate } : parameters.endDate = endDate; }
    if (salesman != '') { parameters == undefined ? parameters = { salesman: salesman } : parameters.salesman = salesman; }
    if (selectedStatus != '') { parameters == undefined ? parameters = { status: selectedStatus } : parameters.status = selectedStatus; }
    if (channelId != 0) { parameters == undefined ? parameters = { channelId: channelId } : parameters.channelId = channelId; }

    return this.  genericServ.getRequest(url, parameters, 0);
  }

  /**
   * Get Excel file 
   * @param unitId 
   * @param creationDate 
   * @param salesman 
   * @param reference 
   * @param selectedStatus 
   * @returns 
   */
  getPaybillListExcel(unitId: number = 0, creationDate: string = null, endDate: string = null, salesman: string = '', reference: string = "", selectedStatus: string = "", channelId: number = 0) {
    let url = `Paybill/excel`;
    let parameters;
    if (unitId != 0) { parameters == undefined ? parameters = { unitId: unitId } : parameters.unitId = unitId; }
    if (creationDate != '') { parameters == undefined ? parameters = { creationDate: creationDate } : parameters.creationDate = creationDate; }
    if (endDate != '') { parameters == undefined ? parameters = { endDate: endDate } : parameters.endDate = endDate; }
    if (salesman != '') { parameters == undefined ? parameters = { salesman: salesman } : parameters.salesman = salesman; }
    if (reference != '') { parameters == undefined ? parameters = { reference: reference } : parameters.reference = reference; }
    if (selectedStatus != '') { parameters == undefined ? parameters = { status: selectedStatus } : parameters.status = selectedStatus; }
    if (channelId != 0) { parameters == undefined ? parameters = { channelId: channelId } : parameters.channelId = channelId; }
    console.log(parameters);
    return this.genericServ.getExcelRequest(url, parameters, 0);
  }

  /**
   * Search paybils by reference number
   * @param reference 
   * @returns 
   */
  getPaybillByReference(reference: string) {
    return this.genericServ.getRequest(`paybill/detail/${reference}`, {}, 0);
  }

  updatePaybill(reference: string, detailsUpdate: any) {
    return this.genericServ.putRequest(`paybill/recovery/${reference}`, {}, 0, detailsUpdate);
  }

  /**
   * Gets paybill data to print from database
   *
   * @param {string} remissionNumber - Remission number to get data
   * @returns {Observable<paybill>} - Observable with remission type
   * @memberof CustomerBalanceService
   */
  getpaybillPrintedpdf(reference: string): Observable<any> {
    return this.genericServ.getPdfRequest(`paybill/print/${reference}`, {}, 0);
  }

  liquidatePaybill(paybillNumber: string, rtmReferente: string): Observable<any> {
    let url = `paybill/liquidate/${paybillNumber}`;
    return this.genericServ.putRequest(url, {}, 0, { RTMReference: rtmReferente });
  }

    /**
   * Get paybillExpired list
   * @param org
   * @param selectedOrg 
   * @param selectedCeve 
   * @param selectedChannel 
   * @param dispatchDate 
   * @param dispatchDateEnd 
   * @returns 
 
   */
    getPaybillListExpired( org : number, selectedOrg: number , selectedCeve: number , selectedChannel: number , collectionDate: string , collectionDateEnd  : string) {
      let url = `paybillExpired/listExpired/${org}/${selectedOrg}/${selectedCeve}/${selectedChannel}/${collectionDate}/${collectionDateEnd}`;
      let parameters;  

      if (org != 0) { parameters == undefined ? parameters = { org: org } : parameters.Org = org; }
      if (selectedOrg !=undefined) { parameters == undefined ? parameters = { selectedOrg: selectedOrg } : parameters.selectedOrg = selectedOrg; }
      if (selectedCeve != 0) { parameters == undefined ? parameters = { selectedCeve: selectedCeve } : parameters.selectedCeve = selectedCeve; }
      if (selectedChannel != 0) { parameters == undefined ? parameters = { selectedChannel: selectedChannel  } : parameters.selectedChannel = selectedChannel; }
      if (collectionDate) { parameters == undefined ? parameters = { collectionDate: collectionDate } : parameters.collectionDate =collectionDate; }
      if (collectionDateEnd) { parameters == undefined ? parameters = { collectionDateEnd: collectionDateEnd } : parameters.collectionDateEnd =collectionDateEnd; }
       console.log(parameters);
       return this.genericServ.getRequest(url, parameters, 0);
    }

     /**
   * Get paybillExpired list
   * @param org
   * @param selectedOrg 
   * @param selectedCeve 
   * @param selectedChannel 
   * @param collectionDate 
   * @param collectionDateEnd 
   * @returns 
 
   */
     getPaybillListMultichannel( org : number, selectedOrg: number , selectedCeve: number , selectedChannel: number , selectedRoute: number , collectionDate: string,  collectionDateEnd: string ) {
      let url = `paybillMultichannel/listMultichannel/${org}/${selectedOrg}/${selectedCeve}/${selectedChannel}/${selectedRoute}/${collectionDate}/${collectionDateEnd}`;
      let parameters;  

      if (org != 0) { parameters == undefined ? parameters = { org: org } : parameters.org = org; }
      if (selectedOrg !=undefined) { parameters == undefined ? parameters = { selectedOrg: selectedOrg } : parameters.selectedOrg = selectedOrg; }
      if (selectedCeve != 0) { parameters == undefined ? parameters = { selectedCeve: selectedCeve } : parameters.selectedCeve = selectedCeve; }
      if (selectedChannel != 0) { parameters == undefined ? parameters = { selectedChannel: selectedChannel  } : parameters.selectedChannel = selectedChannel; }
      if (selectedRoute != 0) { parameters == undefined ? parameters = { selectedRoute:selectedRoute  } : parameters.selectedRoute = selectedRoute; }
      if (collectionDate) { parameters == undefined ? parameters = { collectionDate: collectionDate } : parameters.collectionDate =collectionDate; }
      if (collectionDateEnd) { parameters == undefined ? parameters = { collectionDateEnd: collectionDateEnd } : parameters.collectionDateEnd =collectionDateEnd; }
       console.log(parameters); 
       return this.  genericServ.getRequest(url, parameters, 0);
    }

  /**
   * Get paybillExpired list
   * @param org
   * @param selectedOrg 
   * @param selectedCeve 
   * @param selectedChannel 
   * @param collectionDate 
   * @param collectionDateEnd 
   * @returns 
 
   */
    getPaybillListExpiredpdf( org : number, selectedOrg: number , selectedCeve: number , selectedChannel: number , collectionDate: string, collectionDateEnd  : string) {
      let url = `paybillExpired/listExpiredpdf/${org}/${selectedOrg}/${selectedCeve}/${selectedChannel}/${collectionDate}/${collectionDateEnd}`;
      let parameters;  

      if (org != 0) { parameters == undefined ? parameters = { org: org } : parameters.org = org; }
      if (selectedOrg !=undefined) { parameters == undefined ? parameters = { selectedOrg: selectedOrg } : parameters.selectedOrg = selectedOrg; }
      if (selectedCeve != 0) { parameters == undefined ? parameters = { selectedCeve: selectedCeve } : parameters.selectedCeve = selectedCeve; }
      if (selectedChannel != 0) { parameters == undefined ? parameters = { selectedChannel: selectedChannel  } : parameters.selectedChannel = selectedChannel; }
      if (collectionDate) { parameters == undefined ? parameters = { collectionDate: collectionDate } : parameters.collectionDate =collectionDate; }
      if (collectionDateEnd) { parameters == undefined ? parameters = { collectionDateEnd: collectionDateEnd } : parameters.collectionDateEnd =collectionDateEnd; }
       console.log(parameters);
       return this.  genericServ.getPdfRequest(url,  0);
    }

    getpaybillPrintedPdf(reference: string): Observable<any> {
      return this.genericServ.getPdfRequest(`paybill/print/${reference}`, {}, 0);
    }

}
