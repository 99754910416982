<div class="content-heading">
  <div class="header-Container">
    <h3>Vales de equipo vencidos</h3>
    <small>Vales vencidos</small>
  </div>
</div>

<div class="container-fluid">
<div class="form-container">
  <div class="row">
      <div class="form-group col col-md-4">   
          <label class="form-label">Fecha</label>
          <input class="form-control" bsDatepicker [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }" name="LabelDate"
                 [(ngModel)]="collectionDate" placeholder="Fecha" disabled />            
      </div>
      <div class="form-group col col-md-4">
          <label class="form-label">Organizaci&oacute;n</label>
          <select title="Org" class="form-control" [(ngModel)]="orgId" (ngModelChange)="changeFilterOrg($event)" required>
              <option [value]="0" selected>TODOS</option>
              <option *ngFor="let unit of org" [value]="unit.UnitId">{{unit.OrgName}}</option>
          </select>
      </div>
      <div class="form-group col col-md-4">
          <label class="form-label">Centro de ventas</label>
          <ceq-unit-autocomplete [loading]="working"
              [unitOrgId]="orgId"
              [listType]="'CEVE'"
              (onUnitClear)="clearOutputNode()"
              (onUnitSelected)="setOutputNode($event)">
          </ceq-unit-autocomplete>


          
      </div>
  </div>
  <div class="row">
      <div class="form-group col col-md-4">
          <label class="form-label">Canal</label>
          <br>
          <select title="Channel" class="form-control" name="Channels" [(ngModel)]="selectedChannel" (change)="changeChannel()">
          <option value="0">TODOS</option>
          <option *ngFor="let ch of channelList" [value]="ch.ChannelCode">
              {{ch.ChannelName.toUpperCase() }}
          </option>
          </select>
      </div>

      <div class="form-group col col-md-4">
        <label class="form-label">Fecha Inicio</label>
        <input class="form-control"
               type="date"
               [(ngModel)]="selectedDate"
               min="{{ minDate }}"
               (change)="onDateSelected('start')" />
      </div>
  
    <div class="form-group col col-md-4">
      <label class="form-label">Fecha Final</label>
      <input class="form-control"
             type="date"
             [(ngModel)]="selectedDateEnd"
             max="{{ maxDate }}"
             (change)="onDateSelected('end')" />
    </div>


      <!-- <div class="form-group col col-md-4">
        <label class="form-label">Fecha a Cobro</label>
        <input class="form-control"
               type="date"
               [(ngModel)]="selectedDate"
               max="{{ maxDate }}"
               (change)="onDateSelected()" />
    </div> -->
    <div class="form-group col col-md-4">
        <br>
        <button type="submit" class="btn btn-labeled btn-outline-success mb-2" (click)="printPaybillExpired()">                                            
          <span class="btn-label"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></span>
          <span>Exportar a PDF</span>
        </button>
      </div>

      
  </div>
</div>
</div>

<app-loading *ngIf="loading"></app-loading>
<div class="container-fluid" *ngIf="!loading">
  <div class="form-container">
      <div class="data-container black-text">
          <div class="d-flex">  
              <div class="column_width center-data" *ngFor="let header of cols">
                  <div class="column_width center-data background-nav-color"> {{header}}</div>
              </div>          
          </div>
          <div class="d-flex">
              <div class="row" *ngFor="let header of cols">
                  <div class="col">
                      <div class="column_width center-data" *ngFor="let row of loadData">
                          <!-- <input *ngIf="header == 'CEVE'" title="{{row[header]}}" type="text"  class="form-control center-data m-left" value="{{row[header]}}" name="{{row[header]}}" disabled>
                          <input *ngIf="header == 'CANAL'" title="{{row[header]}}" type="text"  class="form-control center-data m-left" value="{{row[header]}}" name="{{row[header]}}" hidden>
                           -->
                          <input title="{{row[header]}}"  class="form-control right-data m-left" 
                                value="{{row[header]}}" name="{{row[header]}}" required max="999" min="0" disabled>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <br>
  </div>
</div>  
