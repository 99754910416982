import { Component, OnInit, ViewChild } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { PaybillExpiredPrinted } from '../../common/models/PaybillExpiredPrinted.Model';
import { MultichannelService } from '../multichannel.service';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { UserLogin } from 'src/app/ceq/common/models/UserLogin.Model';
import { unit } from '../../catalog/factory/factory-detail/factory-detail.component';
import { PaybillService } from '../../self-service/balance/paybill/paybill.service';
import { CeqTableComponent } from '../../common/ceq-table/ceq-table.component';
import * as moment from 'moment';
import { GridOptions } from 'ag-grid-community';
import { EditChildRendered } from '../../common/renders/edit-child-rendered';
import { PrintChildRendered } from 'src/app/ceq/common/renders/print-child-rendered';
import { PaybillList } from 'src/app/ceq/common/models/PaybillList.Model';
import { CeveService } from '../../catalog/ceve/ceve.service';
import { GlobalFunctionsService } from '../../common/functions/global-functions.service';
import { Ceve } from "../../common/models/Ceve.Model";
import Swal from 'sweetalert2';
import { result } from 'lodash';

const levelType: string = 'CEVE'; // Corresponds with level type in CeqLevel table

@Component({
  selector: 'app-paybill-expired-print',
  templateUrl: './paybill-expired-print.component.html',
  styleUrls: ['./paybill-expired-print.component.scss']
})

export class PaybillExpiredPrintComponent implements OnInit {
  unit: unit[] = [];
  PaybillExpiredPrinted: PaybillExpiredPrinted[] = [];

  dateDay : string= "";
  Ceve: string;
  org: string;
  channel:string;
  collectionDate: string;
  collectionDateEnd: string;

  ceveid:number;
  orgid: string;
  loading: boolean = false;
  working: boolean = false;

  ceve: Ceve = new Ceve();

  ceqListGrid:any[] = [];
  cols: string[] = [];
  loadData: any[] = [];

  cevename: string;
  orgname: string;
  
  userLogin: UserLogin;
  datepipe: DatePipe = new DatePipe('en-US');

  orgn: any;
  Name: string;
  

  constructor(
    private router: ActivatedRoute, 
    private paybillService: PaybillService, 
    private multichannelService: MultichannelService, 
    private settings: SettingsService,
    private globalService: GlobalFunctionsService, 
  private ceveservice: CeveService,) {}

  ngOnInit(): void {


    this.dateDay = this.datepipe.transform(new Date(), 'dd-MM-yyyy HH:mm:ss');

    this.orgid= this.router.snapshot.params['org'] ;
    this.org =this.router.snapshot.params['orgId'] ;
    this.Ceve = this.router.snapshot.params['ceveId'];
    this.channel =this.router.snapshot.params['channelId'];
    this.collectionDate =this.router.snapshot.params['collectionDate'] ;
    this.collectionDateEnd =this.router.snapshot.params['collectionDateEnd'] ;
    this.getPaybillListExpiredList();
    this.ceveservice.GetCeveById(parseInt(this.Ceve)).subscribe(data => {
    this.ceve = data;
    });
    }

    getPaybillListExpiredList() {

      this.paybillService
      .getPaybillListExpired(
        this.orgid= this.router.snapshot.params['org'] ,
        this.org =this.router.snapshot.params['orgId'] ,
         this.ceve = this.router.snapshot.params['ceveId'],
         this.channel =this.router.snapshot.params['channelId'],
         this.collectionDate =this.router.snapshot.params['collectionDate'] ,
         this.collectionDateEnd =this.router.snapshot.params['collectionDateEnd'],
       )
       .subscribe(result => {
        if( result.length > 0){
          this.ceqListGrid = result;
          this.cols = Object.keys(result[0]);
          this.cols = this.cols.filter(x => x !=  'CANAL');
          //console.log(this.cols);
        }

        this.filterChannel();
        this.loading = false;

       
      });
  }

  filterChannel() {

      this.loadData = this.ceqListGrid;
 
  }

  // gridEmiter(gridOptions: GridOptions): void {
  //   this.gridOptions = gridOptions;
  //   this.ceqListGrid.gridOptions = this.gridOptions;
  //   this.ceqListGrid.endPoint = '';
  //   this.ceqListGrid.data = [];
  // }



  getPaybillExpiredPrintedPdf(){

    Swal.fire({
      title: 'Cargando Archivo...',
      html: 'Por favor espere',
      didOpen: () => {
        Swal.showLoading()
      }
    })
    this.paybillService.getPaybillListExpiredpdf(
    this.orgid= this.router.snapshot.params['org'] ,
    this.org =this.router.snapshot.params['orgId'] ,
     this.Ceve = this.router.snapshot.params['ceveId'],
     this.channel =this.router.snapshot.params['channelId'],
     this.collectionDate =this.router.snapshot.params['collectionDate'],
     this.collectionDateEnd =this.router.snapshot.params['collectionDateEnd'],

  )
    .subscribe(result => {
      if (result == null) {
        console.log("NODATA");
        Swal.close();
        return;
      }
      console.log(result);
      let blob = new Blob([result.body], { type: 'application/pdf' });
      var fileUrl = URL.createObjectURL(blob);
      window.open(fileUrl).print();
      Swal.close();
    }, err => {
      console.log(err);
      Swal.close();
    });
  } 

  backClick(): void {
    window.close();
  }

  /**
   * Print document
   *
   */
  print(): void {

    this.getPaybillExpiredPrintedPdf();
   }
}

