import { BaseModel } from "./BaseModel.Model"

export class EmployeeRoute extends BaseModel {

    constructor() {
        super();
        this.Org = 0;
        this.EmployeeNumber = "";
        this.EmployeeName = "";
        this.EmployeeCode="";
        this.EmployeeTypeName = "";
        this.RouteCode = "";
        this.RouteId = 0;
        this.ChannelCode= "";
        this.ChannelId= 0;
        this.ChannelName= "";
    }

    Org: number;
    EmployeeCode:string;
    EmployeeName: string;
    EmployeeNumber: string;
    EmployeeTypeName: string;
    RouteId: number;
    RouteCode: string;
    RouteName: string;
    ChannelCode: string;
    ChannelId: number;
    ChannelName: string;
    CreatedAt: Date;
    CreatedBy: string;
    ModifiedAt: Date;
    ModifiedBy: string;
}