import { Component, OnInit } from '@angular/core';
import { MultichannelService } from '../multichannel.service';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { GlobalFunctionsService } from '../../common/functions/global-functions.service';
import { EquipmentloanserviceService } from '../../self-service/equipment-loan/equipmentloanservice.service';
import { ContainerService } from '../../catalog/container/container/container.service';
import { Unit } from '../../common/models/Unit.Model';
import { Channel } from '../../common/models/Channel.Model';
import { Route } from '../../common/models/Route.Model';
import { Container } from '../../common/models/Container.Model';
import { PromiseStatus } from 'ag-grid-community';
import { CeveByContainerService } from '../../catalog/ceve/ceveByContainer.service';
import { number } from 'ngx-custom-validators/src/app/number/validator';
import { ChannelService } from '../../catalog/channel/channel.service';
import { MultiselectOption } from '../../common/ceq-multiselect/ceq-multiselect.component';
import { AutocompleteNode } from '../../common/ceq-unit-autocomplete/ceq-unit-autocomplete.component';
import { CeveService } from '../../catalog/ceve/ceve.service';

@Component({
  selector: 'app-route-balance-report',
  templateUrl: './route-balance-report.component.html',
  styleUrls: ['./route-balance-report.component.scss']
})
export class RouteBalanceReportComponent implements OnInit {

  constructor(
    private multichannelSerice: MultichannelService,
    private settings: SettingsService,
    private globalService: GlobalFunctionsService,
    private equipmentLoanService: EquipmentloanserviceService,
    private containerService: ContainerService,
    private ceveByContainerService: CeveByContainerService,
    private channelService: ChannelService,
    private ceveService: CeveService,
  ) { }

  working: boolean = false;

  //filters
  startDate: string;
  endDate: string;
  maxDate: string;
  resalta: boolean = false;

  //Filters
  selectedUnit: AutocompleteNode = undefined;
  channels: Channel[] = [];
  channelId: number = undefined;
  routes: Route[] = [];
  routeId: number = undefined;
  containerTypeList: MultiselectOption[] = [];
  selectedcontainers: number[] = this.containerTypeList.map(x => x.Id);
  asChannelCode: string = '561';


  ngOnInit(): void {
    this.startDate = this.globalService.getStringDate(new Date(), false, 0);
    this.endDate = this.globalService.getStringDate(new Date(), false, 0);
    this.maxDate = this.globalService.getStringDate(new Date(), true, -3);
  }

  onUnitClear() {
    this.selectedUnit = undefined;
    this.channels = [];
    this.channelId = undefined;
    this.routes = [];
    this.routeId = undefined;
    this.containerTypeList = [];
    this.selectedcontainers = [];
  }

  async onUnitSelected(event: AutocompleteNode) {
    this.selectedUnit = event;
    await this.getContainers();
    await this.getChannelsByCeve();
  }

  async onChannelSelected(event: any) {
    await this.getRoutesByChannel();
  }

  async onRouteSelected(event: any) {
    // console.log(this.routeId);
  }

  onStartDateSelected() {
    if (new Date(this.startDate) > new Date(this.endDate)) {
      this.endDate = this.startDate
    }
  }

  onEndDateSelected() {
    if (new Date(this.startDate) > new Date(this.endDate)) {
      this.startDate = this.endDate
    }
  }

  async getChannelsByCeve() {
    this.working = true;

    await this.ceveService.getChannelsbyCeve(this.selectedUnit.UnitId)
      .toPromise()
      .then(data => {
        this.working = false;
        this.channels = data.filter(x => x.ChannelCode != this.asChannelCode).map(x => { return { UnitId: x.ChannelId, ChannelCode: x.ChannelCode, ChannelName: x.ChannelName } });
        if (this.channels.length > 0) {
          this.channels.unshift({
            Org: this.settings.getUser().Org,
            UnitId: 0,
            ChannelCode: '',
            ChannelName: 'Todos',
            CreatedAt: new Date(),
            CreatedBy: '',
            ModifiedAt: new Date(),
            ModifiedBy: ''
          });
          this.channelId = this.channels[0].UnitId;
          this.onChannelSelected(this.channelId);
        }
      })
      .catch(async (error) => {
        this.working = false;
        console.error(error);
        await this.globalService.messageToast(error.error.Message, 'error');
      });

  }

  async getRoutesByChannel() {
    this.working = true;
    this.equipmentLoanService.getRoutesByChannel(this.selectedUnit.UnitId, this.channelId)
      .toPromise()
      .then(async resp => {
        this.working = false;
        //Filtro rutas de AS
        this.routes = resp.filter(x => x.ChannelCode != this.asChannelCode);
        if (this.routes.length > 0 || this.channelId == 0) {
          this.routes.unshift({
            Org: this.settings.getUser().Org,
            RouteId: 0,
            ChannelId: 0,
            CeveId: 0,
            RouteCode: '',
            RouteName: 'Todos',
            ChannelCode: '',
            ExpirePaybill: 0,
            CreatedAt: new Date(),
            CreatedBy: '',
            ModifiedAt: new Date(),
            ModifiedBy: ''
          });
          this.routeId = this.routes[0].RouteId;
        }
      })
      .catch(async (error) => {
        this.working = false;
        console.error(error);
        await this.globalService.messageToast(error.error.Message, 'error');
      });
  }

  async getContainers() {
    this.working = true;
    await this.containerService.getContainersByContainerOrg(this.settings.getUser().Org, 0, 0)
      .toPromise()
      .then(async resp => {
        this.working = false;
        let tmpContainers = resp.map(x => { return { Checked: false, Id: x.ContainerId, Text: x.ContainerCode } });
        if (resp.length > 0) {
          //Consulto el equipo configurado por defecto
          let initialContainers = await this.getInitialContainers();
          //si no hay equipo configurado por defecto, selecciono todos el equipo
          if (initialContainers.length == 0) {
            initialContainers = tmpContainers.map(x => x.Id);
          }
          initialContainers.forEach(item => {
            let c = tmpContainers.find(x => x.Id == item);
            if (c != undefined && c != null) {
              c.Checked = true;
            }
          })

          this.containerTypeList = tmpContainers;
          this.selectedcontainers = this.containerTypeList.filter(x => x.Checked == true).map(x => x.Id);
        }
      })
      .catch(async error => {
        this.working = false;
        console.error(error);
        await this.globalService.messageToast(error.error.Message, 'error');
      })
  }

  async getInitialContainers(): Promise<number[]> {
    let containerIds: number[] = [];
    await this.ceveByContainerService.getCheckedRouteBalanceContainers(this.selectedUnit.UnitId)
      .toPromise()
      .then(resp => {
        containerIds = resp;
      })
      .catch(err => {
        console.error(err);
      });
    return new Promise(resolve => resolve(containerIds));
  }

  onContainersFilterChange(event: MultiselectOption[]) {
    this.selectedcontainers = event.map(x => x.Id);
  }

  onContainerCheckedChange(event: MultiselectOption) {

  }


  async Download() {

    let maxDaysRange: number = 62;
    var diff = Math.abs(new Date(this.endDate).getTime() - new Date(this.startDate).getTime());
    var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    if (diffDays >= maxDaysRange) {
      this.globalService.messageToast(`El rango de fechas no puede ser mayor a ${maxDaysRange} días.`, 'error');
      return;
    }

    this.working = true;
    await this.globalService.messageToast("Descargando Archivo", 'loading');
    await this.multichannelSerice.getRouteBalanceReport(this.selectedUnit.UnitId, this.channelId, this.routeId, this.selectedcontainers, this.startDate, this.endDate)
      .toPromise()
      .then(async resp => {
        if (resp == null) {
          this.working = false;
          await this.globalService.messageToast("No se ha encontrado información.", 'warning');
          return;
        }
        let blob = new Blob([resp], { type: resp.type });
        const anchor = document.createElement('a');
        anchor.download = `Reporte Saldo de Rutas.xlsx`;
        anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
        anchor.click();
        this.working = false;
        await this.globalService.messageToast("Archivo Descargado", 'success');
      })
      .catch(async error => {
        this.working = false;
        console.error(error);
        await this.globalService.messageToast(error.error.Message, 'error');
      });
  }

}
