<div class="content-heading">
    <div class="header-container">
        <h3>Reporte de Cambios de Equipo</h3>
        <small>Reporte Web</small>
    </div>
</div>

<div class="container-fluid">
    <div class="card card-default">
        <div class="card-body">
            <div class="row">
                <div class="col-12 col-md-4 col-sm-6">src/app/ceq/enterprise/remission
                    <div class="form-group">
                        <label class="form-label">Origen</label>
                        <ng-autocomplete
                                         [placeholder]="'Búsqueda'"
                                         [disabled]="false"
                                         [data]="nodes"
                                         [searchKeyword]="'UnitDescription'"
                                         [itemTemplate]="itemInputTemplate"
                                         [notFoundTemplate]="notFoundinputTemplate"
                                         [debounceTime]="600"
                                         [minQueryLength]="3"
                                         (selected)="detailOriginSelected()"
                                         (inputCleared)="detailOriginCleared()"
                                         [(ngModel)]="detailOrigin">
                        </ng-autocomplete>
                        <ng-template #itemInputTemplate let-item>
                            <a [innerHTML]="item.UnitDescription"></a>
                        </ng-template>
                        <ng-template #notFoundinputTemplate let-notFound>
                            <div [innerHTML]="'No se encontraron coincidencias'"></div>
                        </ng-template>
                    </div>
                </div>
                <div class="col-12 col-md-4 col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Destino</label>
                        <ng-autocomplete
                                         [placeholder]="'Búsqueda'"
                                         [disabled]="false"
                                         [data]="nodes"
                                         [searchKeyword]="'UnitDescription'"
                                         [itemTemplate]="itemInputTemplate"
                                         [notFoundTemplate]="notFoundinputTemplate"
                                         [debounceTime]="600"
                                         [minQueryLength]="3"
                                         (selected)="detailDestinationSelected()"
                                         (inputCleared)="detailDestinationCleared()"
                                         [(ngModel)]="detailDestination">
                        </ng-autocomplete>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-4 col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Etiqueta</label>
                        <input class="form-control" [(ngModel)]="detailLabel">
                    </div>
                </div>
                <div class="col-12 col-md-4 col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Fecha Inicio modificaci&oacute;n de etiqueta</label>
                        <input class="form-control" type="date" [(ngModel)]="detailLabelStartDate" max="{{ maxDate }}">
                    </div>
                </div>
                <div class="col-12 col-md-4 col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Fecha Fin modificaci&oacute;n de etiqueta</label>
                        <input class="form-control" type="date" [(ngModel)]="detailLabelEndDate" max="{{ maxDate }}">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-4 col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Remisi&oacute;n</label>
                        <input class="form-control" [(ngModel)]="detailRem">
                    </div>
                </div>
                <div class="col-12 col-md-4 col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Fecha Inicio recepci&oacute;n de remisi&oacute;n</label>
                        <input class="form-control" type="date" [(ngModel)]="detailRemStartDate" max="{{ maxDate }}">
                    </div>
                </div>
                <div class="col-12 col-md-4 col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Fecha Fin recepci&oacute;n de remisi&oacute;n</label>
                        <input class="form-control" type="date" [(ngModel)]="detailRemEndDate" max="{{ maxDate }}">
                    </div>
                </div>
            </div>

            <div class="row">
             <div class="col-12 col-md-4 col-sm-6">
                <button class="mr-1  btn btn-labeled btn-outline-success mb-2" [disabled]="downloadingDetail" (click)="downloadDetail()">
                    <span class="btn-label">
                    <i class="fa fa-file-excel" aria-hidden="true" *ngIf="!downloadingDetail"></i>
                    <i class="fa fa-circle-notch fa-spin" *ngIf="downloadingDetail"></i>
                    </span>
                    <span>Descargar reporte Detalle</span>
                </button>
             </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-12 col-md-4 col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Origen</label>
                        <ng-autocomplete
                                         [placeholder]="'Búsqueda'"
                                         [disabled]="false"
                                         [data]="nodes"
                                         [searchKeyword]="'UnitDescription'"
                                         [itemTemplate]="itemInputTemplate"
                                         [notFoundTemplate]="notFoundinputTemplate"
                                         [debounceTime]="600"
                                         [minQueryLength]="3"
                                         (selected)="totalOriginSelected()"
                                         (inputCleared)="totalOriginCleared()"
                                         [(ngModel)]="totalOrigin">
                        </ng-autocomplete>
                    </div>
                </div>
                <div class="col-12 col-md-4 col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Destino</label>
                        <ng-autocomplete
                                         [placeholder]="'Búsqueda'"
                                         [disabled]="false"
                                         [data]="nodes"
                                         [searchKeyword]="'UnitDescription'"
                                         [itemTemplate]="itemInputTemplate"
                                         [notFoundTemplate]="notFoundinputTemplate"
                                         [debounceTime]="600"
                                         [minQueryLength]="3"
                                         (selected)="totalDestinationSelected()"
                                         (inputCleared)="totalDestinationCleared()"
                                         [(ngModel)]="totalDestination">
                        </ng-autocomplete>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-4 col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Remisi&oacute;n</label>
                        <input class="form-control" [(ngModel)]="totalRem">
                    </div>
                </div>
                <div class="col-12 col-md-4 col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Fecha Inicio recepci&oacute;n de remisi&oacute;n</label>
                        <input class="form-control" type="date" [(ngModel)]="totalRemStartDate" max="{{ maxDate }}">
                    </div>
                </div>
                <div class="col-12 col-md-4 col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Fecha Fin recepci&oacute; de remisi&oacute;n</label>
                        <input class="form-control" type="date" [(ngModel)]="totalRemEndDate" max="{{ maxDate }}">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-4 col-sm-6">
                    <button class="mr-1  btn btn-labeled btn-outline-success mb-2" [disabled]="downloadingTotals" (click)="downloadTotal()">
                        <span class="btn-label">
                        <i class="fa fa-file-excel" aria-hidden="true" *ngIf="!downloadingTotals"></i>
                        <i class="fa fa-circle-notch fa-spin" *ngIf="downloadingTotals"></i>
                        </span>
                        <span> Descargar reporte Totales</span>
                       </button>
                </div>
            </div>

        </div>
    </div>
</div>