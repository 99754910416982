import { DatePipe } from '@angular/common'
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ProxyGet } from '../../common/services/proxy.service';
import { Params, ResponseJson, SingleResponseJson } from '../../common/models/response';
import { ModalDirective } from 'ngx-bootstrap';
import { EditChildRendered } from '../../common/renders/edit-child-rendered';
import { DeleteChildRendered } from '../../common/renders/delete-child-rendered';
import * as moment from 'moment';
import { OrgService } from '../../catalog/org/org.service';
import { ConfigDTO, SettingsService } from '../../../core/settings/settings.service';
import { UserLogin } from '../../common/models/UserLogin.Model';
import { Org } from '../../common/models/Org.Model';
import { MultichannelService } from '../multichannel.service';
import { Channel } from '../../common/models/Channel.Model';
import { CeveByContainerService } from '../../catalog/ceve/ceveByContainer.service';
import { Container } from '../../common/models/Container.Model';
import { RouteChannelModel, DeliveryRoutesDayGroup, SendEquipmentVoucher } from '../../common/models/RouteChannelModel';
import { RouteOperationDayModel } from '../../common/models/RouteOperationDayModel';
import Swal from 'sweetalert2';
import { AutocompleteNode } from '../../common/ceq-unit-autocomplete/ceq-unit-autocomplete.component';
import { CeveService } from '../../catalog/ceve/ceve.service';

@Component({
  selector: 'app-equipment-return',
  templateUrl: './equipment-return.component.html',
  styleUrls: ['./equipment-return.component.scss']
})
export class EquipmentReturnComponent implements OnInit {

  loading: boolean = false;
  typeDatTable: boolean[] = [];
  completeRoutes: RouteOperationDayModel[] = [];
  RoutesSync: RouteOperationDayModel[] = [];
  RoutesReceived: RouteOperationDayModel[] = [];
  managedContainers: Container[] = [];
  totalContainers: ContainersTotal[] = [];
  titleModal: string = "";

  minDate: string = moment(new Date()).format('YYYY-MM-DD');
  maxDate: string = moment(new Date()).format('YYYY-MM-DD');
  selectedDate: string = moment(new Date()).format('YYYY-MM-DD');
  now: Date = new Date();
  validateModal: boolean = true;

  orgList: Org[] = [];
  ceqGrid: DeliveryRoutesDayGroup[];
  selectedOrg: Org;

  selectedCeve: AutocompleteNode;

  channelList: Channel[] = [];
  selectedChannel: Channel;

  routeCodeTyped: string = "";
  selectedRoute: RouteChannelModel;
  ceqPrintDateC: string = "";
  ceqPrintDateV: string = "";
  ceqPrintSalesman: string = "";
  ceqPrintSupervisor: string = "";
  ceqPrintRute: number = 0;
  ceqPrintReference: string = "";
  ceqPrint: DeliveryRoutesDayGroup[];
  maxQty: number = 0;
  asChannelCode: string = '561';
  loadingModal: boolean = false;

  constructor(
    private datepipe: DatePipe,
    private apiService: ProxyGet,
    private settingsServ: SettingsService,
    private orgService: OrgService,
    private multichannelService: MultichannelService,
    private ceveByContainerService: CeveByContainerService,
    private ceveService: CeveService,
  ) { }

  @ViewChild('acceptModal') acceptModal: ModalDirective;
  @ViewChild('printModal') printModal: ModalDirective;


  @HostListener('window:focus', ['$event'])
  async onFocused() {
    let currentDate = moment(new Date());

    let ddiff = moment(currentDate).diff(this.now, 'days');

    if (ddiff > 1) {
      this.minDate = moment(new Date()).format('YYYY-MM-DD');
      this.maxDate = moment(new Date()).format('YYYY-MM-DD');
      this.selectedDate = moment(new Date()).format('YYYY-MM-DD');
      this.now = new Date();
      await this.getFilterOrgs();
    }
  }


  async ngOnInit(): Promise<void> {
    this.settingsServ.getSettingsByKey('RemMaxQty').subscribe((value: ConfigDTO) => {
      this.maxQty = Number(value.Value);
    });
    await this.getFilterOrgs();

  }

  //**Obtengo las organizaciones de los nodos asignados al usuario */
  async getFilterOrgs(): Promise<void> {
    this.orgList = [];
    // this.selectedOrg = undefined;
    this.loading = true;

    try {
      let orgs = await this.orgService.getActiveOrgsByUserProfile().toPromise();
      this.orgList = orgs;
      this.selectedOrg = orgs[0];

      this.loading = false;

    } catch (error) {
      this.loading = false;
      Swal.fire('Error', "Ponte en contacto con soporte a sistemas para ayudarte o asistirte con este problema:" + error, 'error');
    }
  }

  changeInput(event, index: number) {
    let e = <KeyboardEvent>event;
    if (Number(event.srcElement.value) > this.maxQty) {
      event.srcElement.value = this.maxQty;
    }
    if (e.key == '+') {
      setTimeout(() => {
        const ele = document.getElementsByClassName("input-number")[index + 1] as HTMLElement;
        if (ele) {
          ele.focus();
        }
      }, 100);
      e.preventDefault();
    } else if (e.key == '-') {
      setTimeout(() => {
        const ele = document.getElementsByClassName("input-number")[index - 1] as HTMLElement;
        if (ele) {
          ele.focus();
        }
      }, 100);
      e.preventDefault();
    } else if (['Enter'].indexOf(e.key) !== -1) {
      this.saveData();
      e.preventDefault();
    } else {
      return;
    }

  }

  /**Obtengo las rutas del centro de ventas seleccionado */
  async getFilterchannels(): Promise<void> {
    this.channelList = [];
    this.selectedChannel = undefined;

    if (this.selectedCeve == undefined) {
      return;
    }

    try {
      let channels = await this.ceveService.getChannelsbyCeve(this.selectedCeve.UnitId).toPromise();
      // exclusion del canal AS
      let filterchannels = channels.filter(x => x.ChannelCode != this.asChannelCode).map(x => { return { UnitId: x.ChannelId, ChannelCode: x.ChannelCode, ChannelName: x.ChannelName } });;
      this.channelList = filterchannels;

      await this.getRoutesSync();

    } catch (error) {
      this.loading = false;
      Swal.fire('Error', "Ponte en contacto con soporte a sistemas para ayudarte o asistirte con este problema:" + error, 'error');
    }

  }

  /**Obtengo el equipo controlado de rutas multicanal */
  async getRouteManagedContainers(): Promise<void> {
    this.managedContainers = [];
    this.totalContainers = [];

    if (this.selectedCeve == undefined) {
      return;
    }

    let routeContainers = await this.ceveByContainerService.getCeveByContainersRouteManaged(this.selectedCeve.UnitId).toPromise();
    this.managedContainers = routeContainers.sort((a, b) => a.ContainerId - b.ContainerId);

    //Genero lista para los totales del equipo
    this.totalContainers = this.managedContainers.map(x => {
      return {
        ContainerId: x.ContainerId,
        ContainerCode: x.ContainerCode,
        Total: 0
      }
    });
  }

  /**Evento que se ejecuta al borrar el filtro de ceve */
  onCeveClear() {
    this.onCeveChanged(undefined);
  }

  /**Evento que se ejecuta al cambiar el filtro de ceve */
  async onCeveChanged(unit: AutocompleteNode): Promise<void> {
    this.selectedCeve = unit;

    this.loading = true;

    await this.getRouteManagedContainers();
    await this.getFilterchannels();

    this.loading = false;
  }

  /**
   * Evento que se dispara al modificar el valor del filtro de ruta
   * @param event Numero de ruta escrito
   */
  OnRouteTyped() {
    if (this.routeCodeTyped.length >= 3) {
      //filtro las rutas por el codigo escrito
      let filteredrotue = this.completeRoutes.filter(x => x.RouteCode.includes(this.routeCodeTyped));
      this.RoutesSync = filteredrotue.sort((a, b) => Number(a.RouteCode) - Number(b.RouteCode));
      this.RoutesReceived = filteredrotue.filter(x => x.Sync == true).sort((a, b) => Number(a.RouteCode) - Number(b.RouteCode));
    }
    else {
      this.RoutesSync = this.completeRoutes.filter(x => x.Sync == true).sort((a, b) => Number(a.RouteCode) - Number(b.RouteCode));
      this.RoutesReceived = this.completeRoutes.filter(x => x.Sync == true).sort((a, b) => Number(a.RouteCode) - Number(b.RouteCode));
    }
    this.calculateTotals();
  }

  /**Obtengo las rutas sincronizadas y recibidas*/
  async getRoutesSync(): Promise<void> {
    this.routeCodeTyped = "";
    this.completeRoutes = [];
    this.RoutesSync = [];
    this.RoutesReceived = [];
    this.loading = true;

    if (this.selectedCeve == undefined) {
      return;
    }

    let channelId = 0;
    if (this.selectedChannel != undefined) {
      channelId = this.selectedChannel.UnitId;
    }

    let data = await this.multichannelService.getOperationDayRoutes(this.selectedCeve.UnitId, channelId, moment(this.selectedDate).format('YYYY-MM-DD')).toPromise();

    this.completeRoutes = data;
    this.RoutesSync = data.filter(x => x.Sync == true).sort((a, b) => Number(a.RouteCode) - Number(b.RouteCode));
    this.RoutesReceived = data.filter(x => x.Sync == true).sort((a, b) => Number(a.RouteCode) - Number(b.RouteCode));

    this.calculateTotals();
    this.loading = false;
  }

  /**Calcula los totales del equipo segun las rutas mostradas  */
  calculateTotals() {
    this.managedContainers.forEach(container => {
      let total = 0;

      this.RoutesReceived.forEach(x => {
        let currentCont = x.Containers.find(x => x.ContainerId == container.ContainerId);
        if (currentCont != null && currentCont != undefined) {
          total += currentCont.VanStock;
        }
      });

      this.totalContainers.find(x => x.ContainerId == container.ContainerId).Total = total;

    });
  }


  /**Evento que se dispara al hacer click en una ruta syncronizada */
  async SyncRouteClick(routeId: number, ruteCode: number) {
    this.titleModal = `Entrega Ruta: ${ruteCode}`;

    this.acceptModal.show();
    this.ceqGrid = [];
    this.loadingModal = true;

    const jsonDta = await this.apiService.callAPI(`routebalance/deliveryRoutes/${routeId}/${this.selectedDate}`, "GET");
    const response = jsonDta as ResponseJson<DeliveryRoutesDayGroup>;
    if (response && response.Success) {
      this.loadingModal = false;
      this.ceqGrid = response.Response;

      this.validateModal = true;
      for (let f of this.ceqGrid) {
        if (f.DeliveryToReceipt != 0) {
          this.validateModal = false;
        }
      }
      if (this.validateModal) {
        setTimeout(() => {
          const ele = document.getElementsByClassName("input-number")[0] as HTMLElement;
          if (ele) {
            ele.focus();
          }
        }, 300);
      } else {
        Swal.fire('Anuncio', "Ya no es posible realizar cambios en esta ruta.", 'warning');
      }

    } else {
      this.loadingModal = false;
      Swal.fire('Error', "Ponte en contacto con soporte a sistemas para ayudarte o asistirte con este problema.", 'error');
    }
  }

  gridOptions = {
    context: this,
    frameworkComponents: {
      childEditRenderer: EditChildRendered,
      childDeleteMessageRenderer: DeleteChildRendered
    },
    suppressHorizontalScroll: true,
    onRowDoubleClicked() { },
    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="margin-left:10px"></i>',
      sortDescending: '<i class="fa fa-sort-down" style="margin-left:10px"></i>',
      sortUnSort: '<i class="fa fa-sort" style="margin-left:10px"></i>'
    }
  }

  updateTable(type: boolean, name: string) {
    if (name == "ContainerCode") {
      this.ceqGrid.sort((a, b) => {
        const nameA = a.ContainerCode.toUpperCase();
        const nameB = b.ContainerCode.toUpperCase();
        if (nameA < nameB) {
          return type ? -1 : 1;
        }
        if (nameA > nameB) {
          return type ? 1 : -1;
        }

        return 0;
      });
    } else if (name == "Unload") {
      this.ceqGrid.sort((a, b) => {
        const nameC = a.Unload;
        const nameD = b.Unload;
        if (nameC < nameD) {
          return type ? -1 : 1;
        }
        if (nameC > nameD) {
          return type ? 1 : -1;
        }

        return 0;
      });
    } else {
      this.ceqGrid.sort((a, b) => {
        const nameE = a.Diff;
        const nameF = b.Diff;
        if (nameE < nameF) {
          return type ? -1 : 1;
        }
        if (nameE > nameF) {
          return type ? 1 : -1;
        }

        return 0;
      });
    }

    this.typeDatTable = [name == "ContainerCode" && type, name == "Unload" && type, name == "Diff" && type];
  }

  changeRange(newValue: string, id: string) {
    var regexStr = '^[0-9]*$';
    let regEx = new RegExp(regexStr);
    let indexToUpdate = this.ceqGrid.findIndex(item => item.ContainerCode === id);
    if (regEx.test(newValue) && Number(newValue) <= this.maxQty) {
      this.ceqGrid[indexToUpdate].VanStock = Number(newValue);
      this.ceqGrid[indexToUpdate].Diff = this.ceqGrid[indexToUpdate].VanStock - this.ceqGrid[indexToUpdate].Unload;
    } else if (regEx.test(newValue)) {
      this.ceqGrid[indexToUpdate].VanStock = this.maxQty;
      this.ceqGrid[indexToUpdate].Diff = this.ceqGrid[indexToUpdate].VanStock - this.ceqGrid[indexToUpdate].Unload;
    }
  }


  async printVale(reference) {
    try {
      const resp = await this.apiService.callAPI(`paybill/print/${reference}`, "GET");
      if (resp == null) {
        Swal.fire('¡Error!', `Ocurrió un error al generar los vales. Detalle: El api retorno un nulo del pdf de refrencia`, 'error');
        return;
      }

      let downloadLink = document.createElement('a');
      const src = `data:text/csv;base64,${resp}`;
      downloadLink.href = src
      downloadLink.setAttribute('download', `${reference}.pdf`);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    } catch (e) {
      Swal.fire('¡Error!', `Ocurrió un error al generar los vales. Detalle:”${e.Error_code}: ${e.Error_msg}”`, 'error');
    };
  }

  async saveData() {
    var valesText = "";
    var recepText = "";

    this.ceqGrid.forEach(element => {
      if (element.Diff < 0) {
        valesText += `${element.ContainerCode} = ${element.Diff}<br />`
      } else if (element.Diff > 0) {
        recepText += `${element.ContainerCode} = ${element.Diff}<br />`
      }
    });
    await Swal.fire({
      title: 'Confirmacion',
      html: "<b>¿Desea continuar con el guardado de la información? </b><br /> Se generara los siguientes vales de entrega <br />" + valesText
        + "<br /> Tambien recepción de los equipos entregados <br />" + recepText,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> &nbsp; Aceptar',
      cancelButtonText: '<i class="fa fa-thumbs-down"></i> &nbsp; Cancelar',
      backdrop: `rgba(0,157,210,0.5)`
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Validando información...',
          html: 'Por favor espere...',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        const userLogin: UserLogin = this.settingsServ.getUser();

        const request: Params = {
          Parameter: [
            {
              Field: "UnitId",
              Value: "",
              Object: userLogin.OrgId
            }, {
              Field: "UserId",
              Value: "",
              Object: userLogin.Id
            }, {
              Field: "Data",
              Value: "",
              Object: this.ceqGrid
            }
          ]
        };

        const jsonDta = this.apiService.callAPIWithParams(`Paybill/creationVoucher`, "PUT", request);
        jsonDta.then((v) => {
          const response = v as SingleResponseJson<SendEquipmentVoucher>;
          Swal.hideLoading();
          if (response && response.Success) {
            this.acceptModal.hide();
            Swal.fire({
              title: '¡Exito!',
              text: "Se guardaron los datos de manera exitosa.",
              icon: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: '<i class="fa fa-thumbs-up"></i> &nbsp; Aceptar',
              backdrop: `rgba(0,157,210,0.5)`
            }).then((result) => {
              if (result.isConfirmed && response.Response.Data.length > 0) {
                this.ceqPrintDateC = this.datepipe.transform(response.Response.CreateDate, 'dd-MM-yyyy');;
                this.ceqPrintDateV = this.datepipe.transform(response.Response.ExpirationDate, 'dd-MM-yyyy');;
                this.ceqPrintRute = response.Response.Data[0].RouteId;
                this.ceqPrintSalesman = response.Response.Salesman;
                this.ceqPrintSupervisor = response.Response.Supervisor;
                this.ceqPrint = response.Response.Data;
                this.ceqPrintReference = response.Response.ReferenceNumber;
                this.printModal.show();
              }
            });
          } else {
            if(response.Error_code == 4589){
              Swal.fire('¡Error!', `La ruta no tiene vendedor asignado`, 'error');
            }else{
              Swal.fire('¡Error!', `Ocurrió un error al generar los vales”`, 'error');
            }
            console.log(`Ocurrió un error al generar los vales. Detalle:”${response.Error_code}: ${response.Error_msg}”`);
          }
        });

      }
    })
  }
}


export interface ContainersTotal {
  ContainerId: number;
  ContainerCode: string;
  Total: number;
}