import { Injectable } from '@angular/core'
import { GenericService } from '../common/services/GenericServices'
import { Observable, observable } from 'rxjs'
import { Ceve } from '../common/models/Ceve.Model'
import { Unit } from '../common/models/Unit.Model';
import { List } from 'lodash';
import { RouteOperationDayModel } from '../common/models/RouteOperationDayModel';
@Injectable({
  providedIn: 'root'
})
export class MultichannelService {

  constructor(private genericService: GenericService) { }

  getRouteBalance(ceveId: number, channelId: number, routeId: number, containers: number[], date: string): Observable<any> {
    return this.genericService.postRequest(`multichannel/routebalance/${ceveId}/${channelId}/${routeId}/${date}`, {}, 0, containers)
  }

  getRouteBalanceResume(ceveId: number, date: string): Observable<BalanceResume> {
    return this.genericService.getRequest(`multichannel/routebalance/resume/${ceveId}/${date}`, {}, 0)
  }

  getRouteBalanceReport(ceveId: number, channelId: number, routeId: number, containers: number[], startDate: string, endDate: string): Observable<any> {
    return this.genericService.postExcelRequest(`report/multichannel/${ceveId}/${channelId}/${routeId}/${startDate}/${endDate}`, {}, 0, containers)
  }

  getCevesByOrg(orgId: number,): Observable<Ceve[]> {
    return this.genericService.getRequest(`userprofile/ceve/byuserorg/${orgId}`, {});
  }
  getOrganizacionesByUser(org: number, userId: number): Observable<List<Unit>> {
    return this.genericService.getRequest(`userprofile/organizationbyuser/${org}/${userId}`, {});
  }

  getOperationDayRoutes(ceveId: number, channelId: number, operationDay: string): Observable<RouteOperationDayModel[]> {
    return this.genericService.getRequest(`routebalance/operationday/${ceveId}/${channelId}/${operationDay}`, {});
  }

  getRouteDataLoad(ceveid: number, dispatchDate: string): Observable<any> {
    return this.genericService.getRequest(`multichannel/routesload/${ceveid}/${dispatchDate}`, {});
  }

  saveRouteLoad(ceveid: number, dispatchDate: string, loadData: any): Observable<any> {
    return this.genericService.postRequest(`multichannel/save/adjustmentsload/${ceveid}/${dispatchDate}`, {}, 0, loadData)
  }

  transferRoutes(ceveid: number, dispatchDate: string, transfers: any): Observable<any> {
    return this.genericService.postRequest(`multichannel/save/transferroutes/${ceveid}/${dispatchDate}`, {}, 0, transfers)
  }

  getChargeRoutes(ceveid: number, dispatchDate: string): Observable<any> {
    return this.genericService.postRequest(`route/cargos/${ceveid}/${dispatchDate}`, {}, 0, null);
  }

  dataToTransfer(routeid: number, dispatchDate: string): Observable<any> {
    return this.genericService.getRequest(`multichannel/datatotransfer/${routeid}/${dispatchDate}`, {});
  }
}

export class BalanceResume {

  constructor() {
    this.inventario_dia_anterior = 0;
    this.cargo = 0;
    this.ajuste_cargo = 0;
    this.devolucion_total = 0;
    this.entrega_adelantada_total = 0;
    this.vale_vendedor = 0;
    this.recuperacion_vale = 0;
    this.recuperacion_vendedor = 0;
    this.no_controlado = 0;
    this.saldo_final_total = 0;

    // this.devolucion_rtm = 0;
    // this.recoleccion_fisica = 0;
    // this.diferencia_vendedor = 0;
    // this.inventario_cliente = 0;
  }

  inventario_dia_anterior: number;
  cargo: number;
  ajuste_cargo: number;
  devolucion_total: number;
  entrega_adelantada_total: number;
  vale_vendedor: number;
  recuperacion_vale: number;
  recuperacion_vendedor: number;
  no_controlado: number;
  saldo_final_total: number;

  // saldo_inicial_qp: number;
  // devolucion_rtm: number;
  // recoleccion_fisica: number;
  // inventario_cliente: number;
  // diferencia_vendedor: number;
}