import { Component, OnInit } from '@angular/core';
import { AutocompleteNode } from '../common/ceq-unit-autocomplete/ceq-unit-autocomplete.component';
import { GlobalFunctionsService } from 'src/app/ceq/common/functions/global-functions.service';
import { SettingsService } from '../../core/settings/settings.service';
import { CeveService } from "../catalog/ceve/ceve.service";
import { OrgService } from '../catalog/org/org.service';
import { UserLogin } from 'src/app/ceq/common/models/UserLogin.Model';
import { Org, OrgByUser } from 'src/app/ceq/common/models/Org.Model';
import { PaybillService } from 'src/app/ceq/self-service/balance/paybill/paybill.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import {Ceve} from '../common/models/Ceve.Model';
import { Unit } from '../common/models/Unit.Model';
import { org } from '../catalog/factory/factory-detail/factory-detail.component';
import { Route } from 'src/app/ceq/common/models/Route.Model';
import { action } from '@circlon/angular-tree-component/lib/mobx-angular/mobx-proxy';
import { Action } from 'rxjs/internal/scheduler/Action';


interface Channel {
  ChannelCode: string;
  ChannelId: number;
  ChannelName: string;
}

@Component({
  selector: 'app-paybill-multichannel',
  templateUrl: './paybill-multichannel.component.html',
  styleUrls: ['./paybill-multichannel.component.scss']
})
export class PaybillMultichannelComponent implements OnInit {

  constructor(
    private settingsService: SettingsService,
    private globalService: GlobalFunctionsService,
      private ceveService: CeveService,
    private orgServ: OrgService,
    private paybillService: PaybillService,
  ) { }

  userLogin: UserLogin;
  userCeves: number[];

  ceveNode: AutocompleteNode;
  initialNode: AutocompleteNode;
  channelList: Channel[] = []; 
  loadData: any[] = [];
  baseLoadData: any[] = [];
  cols: string[] = [];
  selectedChannel: string;
  selectedChannelId: number = 0;
  org: Org[] = [];
  orgId: number = 0;
  working: boolean = false;
  saving: boolean = false;
  currentDate: Date;
  selectedDate: string;
  selectedDateFinal: string;
  selectedOrg: Org;
  selectedCeve: Ceve;
  filtered: boolean = false;
  loading: boolean = false;
  collectionDate: string = moment(new Date()).format('YYYY-MM-DD');
  collectionDateEnd: string = moment(new Date()).format('YYYY-MM-DD');
  datepipe: DatePipe = new DatePipe('en-US');
  ceqListGrid:any[] = [];
  routes: Route[] = [];


  selectedUnit: Unit = undefined;
  maxDate: string;
  minDate: string;

  selectedRoute: number=0;
  noRoute: boolean = false;

  ngOnInit(): void {
    this.currentDate = new Date();
    this.selectedDate = this.globalService.getStringDate(this.currentDate, false, 0);
    this.selectedDateFinal = this.globalService.getStringDate(this.currentDate, false, 0);
    this.userLogin = this.settingsService.getUser();
    this.getOrg();
  }

  //Gets the list of organizations
  getOrg() {
    this.loading = true;
    this.orgServ.getActiveOrgs().subscribe(data => {
      this.org = data;
      this.orgId = this.userLogin.OrgId
    });
  }

  /**
   * Changes data grid filtering the data by the received argument and validates if the country is selected to apply extra filter
   *
   * @param {*} event - Filter received from DOM
   * @memberof RegionListComponent
   */
  changeFilterOrg(event: any): void {
    this.orgId = event;
  }

  // Evento cuando se limpia el combo de centro de ventas
  setOutputNode(event: any){
    this.ceveNode = event;
    this.getChannels();
  }

  // Evento cuando se limpia el combo de centro de ventas
  clearOutputNode() {
    this.ceveNode = this.initialNode;
  }

  getPaybillListExpiredList() {

    if(this.selectedChannel != '0'){
      this.selectedChannelId = this.channelList.find(x => x.ChannelCode == this.selectedChannel).ChannelId;
    } else {
      this.selectedChannelId = 0;
    }
     this.paybillService.getPaybillListMultichannel (this.userLogin.Org, this.ceveNode.UnitOrgId, this.ceveNode.UnitId,this.selectedChannelId,this.selectedRoute,this.selectedDate,this.selectedDateFinal
     ).subscribe(result => {

    if( result.length > 0){
      this.ceqListGrid = result;
      this.baseLoadData = result;
      this.loadData = this.baseLoadData;    
      this.cols = Object.keys(result[0]);
    }else{
    this.loadData = [];
    this.baseLoadData = [];
    }
    this.loading = false;
  });
}

  /**
   * Obtiene los canales de venta asignados al centro de venta
   */
  getChannels() {
    this.channelList = [];
    this.ceveService.getChannelsbyCeve(this.ceveNode.UnitId).subscribe(data => {
      this.channelList = data;
      if(data.length > 0){
        this.selectedChannelId = 0;
        this.selectedChannel = '0';
        this.getRoutes();
      this.getPaybillListExpiredList();
      }else{
        this.globalService.messageToast('No se encontraron canales asignados al centro de venta.', 'warning');
        console.log('No se encontraron canales');
      }
    });
  }


  filterRoutes() {

    this.getPaybillListExpiredList();
    if(this.filtered == false){  
        this.loadData = this.baseLoadData;       
    }
    this.filtered = false;
  }

  cmbRouteChange() {
    this.filterRoutes();
  }

  /**

   * @param channelid 
   */
  changeChannel(){
   
    this.getRoutes();
    this.getPaybillListExpiredList();
    this.getLoadData();

  }

  // Obtiene los datos de vales
  async getLoadData() {
    this.paybillService.getPaybillListMultichannel (this.userLogin.Org, this.ceveNode.UnitOrgId, this.ceveNode.UnitId,this.selectedChannelId,this.selectedRoute, this.selectedDate ,this.selectedDateFinal
    ).subscribe(result => {
      this.loadData = [];
      this.baseLoadData = [];
   if( result.length > 0){
     this.baseLoadData = result;
     this.cols = Object.keys(result[0]);
     this.filterChannel();
   }
   this.loading = false;
 });
    
  }


   // Obtiene las rutas del canal seleccionado
   getRoutes() {

    if(this.selectedChannel != '0'){
      this.selectedChannelId = this.channelList.find(x => x.ChannelCode == this.selectedChannel).ChannelId;
    }else{
      this.selectedChannelId = 0;
    }

    this.ceveService.getCeveRoutes(this.ceveNode.UnitId, this.selectedChannelId).subscribe(data => {
      this.routes = data;
      if (data != null) {
        this.selectedRoute = 0;
        if(this.baseLoadData.length > 0){
          this.filterChannel();
        }else{
          this.getLoadData();
        }
      }
      else {
        this.selectedRoute = null;
      }      
    }, (error) => { }
      , () => { this.cmbRouteChange() });
  }


  // Método que filtrar la tabla de rutas con el canal seleccionado
  filterChannel() {
    this.filtered = true;
      this.loadData = this.baseLoadData; 
  }
  
  async onDateSelected(date: string) {

    if((this.selectedDate) > (this.selectedDateFinal))
    {
      if (date == 'start') {
      this.globalService.messageToast('La fecha inicial no puede ser mayor a la fecha final.', 'error');
      this.selectedDate = this.globalService.getStringDate(this.currentDate, false, 0);
      return;
        
    }
    else{
      this.globalService.messageToast('La fecha final no puede ser menor a la fecha inicial.', 'error');
      this.selectedDateFinal = this.globalService.getStringDate(this.currentDate, false, 0);
      return;
    }
  }

    this.getPaybillListExpiredList();
    this.getLoadData();
  }

  filterdate(){

    this.getLoadData();
  }


}