import { Component, OnInit } from '@angular/core';
import { AutocompleteNode } from '../../common/ceq-unit-autocomplete/ceq-unit-autocomplete.component';

import { GlobalFunctionsService } from 'src/app/ceq/common/functions/global-functions.service';
import { MultichannelService } from '../multichannel.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { CeveService } from "../../catalog/ceve/ceve.service";
import { OrgService } from '../../catalog/org/org.service';

import { UserLogin } from 'src/app/ceq/common/models/UserLogin.Model';
import { Route } from 'src/app/ceq/common/models/Route.Model';
import { Org } from "src/app/ceq/common/models/Org.Model";

interface Channel {
  ChannelCode: string;
  ChannelId: number;
  ChannelName: string;
}

interface Transfer{
  MovementId: number;
  OriginRouteId: number;
  DestinyRouteId: number;
}

interface Balance {
  Container: string;
  Balance: number;
}

@Component({
  selector: 'app-transfer-eq',
  templateUrl: './transfer-eq.component.html',
  styleUrls: ['./transfer-eq.component.scss']
})
export class TransferEqComponent implements OnInit {

  constructor(
    private settingsService: SettingsService,
    private globalService: GlobalFunctionsService,
    private multiService: MultichannelService,
    private ceveService: CeveService,
    private orgServ: OrgService
  ) { }

  userLogin: UserLogin;
  userCeves: number[];

  ceveNode: AutocompleteNode;
  initialNode: AutocompleteNode;
  channelList: Channel[] = [];
  routes: Route[] = [];
  routesOrigin: Route[] = [];
  routesTarget: Route[] = [];
  transfers: Transfer[] = [];
  balances: Balance[] = [];

  originRoute: number;
  destinyRoute: number;
  selectedChannel: string;
  selectedChannelId: number = 0;
  noRoute: boolean = false;
  org: Org[] = [];
  orgId: number = 0;

  working: boolean = false;
  saving: boolean = false;
  currentDate: Date;
  selectedDate: string;
  filtered: boolean = false;
  loading: boolean = false;
  move: number = 0;

  ngOnInit(): void {
    this.currentDate = new Date();
    this.selectedDate = this.globalService.getStringDate(this.currentDate, false, 0);
    this.userLogin = this.settingsService.getUser();
    this.getOrg();
  }

  /**
   * Gets the list of organizations
   */
  getOrg() {
    this.loading = true;
    this.orgServ.getActiveOrgs().subscribe(data => {
      this.org = data;
      this.orgId = this.userLogin.OrgId
    });
    this.ceveNode = { UnitOrgId: this.orgId, UnitId: this.userLogin.UnitId, ParentId: 0, UnitCode: '', UnitDescription: '', LevelType: 'CEVE'};
    this.selectedChannelId = 0;
    this.getRoutes();
  }
  
  /**
   * Changes data grid filtering the data by the received argument and validates if the country is selected to apply extra filter
   *
   * @param {*} event - Filter received from DOM
   * @memberof RegionListComponent
   */
  changeFilterOrg(event: any): void {
    this.orgId = event;
  }

  /**
   * Evento cuando se limpia el combo de centro de ventas
   * @param event 
   */
  setOutputNode(event: any){
    this.balances = [];
    this.transfers = [];
    this.ceveNode = event;
    this.noRoute = false;
    this.getRoutes();
  }

  /**
   * Evento cuando se limpia el combo de centro de ventas
   */
  clearOutputNode() {
    this.ceveNode = this.initialNode;
  }

  /**
   * Obtiene las rutas del canal seleccionado 
   */   
  getRoutes() {
    this.ceveService.getCeveRoutes(this.ceveNode.UnitId, this.selectedChannelId).subscribe(data => {
      this.routes = data;
      this.routesOrigin = data;
      this.routesTarget = data;
      if (data.length > 0) {
        this.originRoute = 0;
        this.destinyRoute = 0;
      }
      else {
        this.noRoute = true;
        this.originRoute = null;
        this.destinyRoute = null;
      }      
    }, (error) => { 
      console.log(error);
    });
  }

  /**
   * Selecciona la ruta de origen
   */
  cmbOriginRouteChange() {
    console.log(this.originRoute);
    this.balances = [];
    this.getEqToTransfer();
    //Se quitan las rutas que no sean multicnal si se selecciona una ruta de autoservicios y viceversa
    let channelCode = this.routes.find(x => x.RouteId == this.originRoute).ChannelCode;
    if(channelCode == '561'){
      this.routesTarget = this.routesTarget.filter(x => x.ChannelCode == '561');
    }else{
      this.routesTarget = this.routesTarget.filter(x => x.ChannelCode != '561');
    }
    //Se quita la ruta seleccionada de la lista de rutas destino
    this.routesTarget = this.routesOrigin.filter(x => x.RouteId.toString() != this.originRoute.toString());
  }

  /**
   * Selecciona la ruta de destino
   */
  cmbDestinyRouteChange() {
    console.log(this.destinyRoute);
    //this.routesOrigin = this.routesTarget.filter(x => x.RouteId.toString() != this.destinyRoute.toString());
  }

  /**
   * Obtiene el nombre de la ruta
   * @param routeId 
   * @returns 
   */
  getRouteName(routeId: number){
    let route = this.routes.find(x => x.RouteId == routeId);
    return route.RouteCode + ' - ' + route.RouteName;
  }

  /**
   * Agrega una transferencia
   * @returns 
   */
  addTransfer(){
    this.balances = [];
    if(this.originRoute == 0 || this.destinyRoute == 0){
      this.globalService.messageToast('Debe seleccionar una ruta de origen y una ruta de destino.', 'warning');
      return;
    }
    this.move = this.move + 1;
    let transfer: Transfer = {
      MovementId: this.move,
      OriginRouteId: this.originRoute,
      DestinyRouteId: this.destinyRoute
    }
    this.transfers.push(transfer);
    this.routesOrigin = this.routesOrigin.filter(x => x.RouteId.toString() != this.originRoute.toString() && x.RouteId.toString() != this.destinyRoute.toString());
    this.routesTarget = this.routesOrigin.filter(x => x.RouteId.toString() != this.originRoute.toString() && x.RouteId.toString() != this.destinyRoute.toString());
    this.originRoute = 0;
    this.destinyRoute = 0;
  }

  /**
   * Se quita elemento de la lista de traspasos
   * @param id 
   */
  delete(id: number){
    let recover = this.transfers.find(x => x.MovementId == id);
    this.transfers = this.transfers.filter(x => x.MovementId != id);
    this.routesOrigin.push(this.routes.find(x => x.RouteId == recover.OriginRouteId));
    this.routesOrigin.push(this.routes.find(x => x.RouteId == recover.DestinyRouteId));
    this.routesOrigin.sort((a, b) => a.RouteId - b.RouteId);
    this.routesTarget.push(this.routes.find(x => x.RouteId == recover.OriginRouteId));
    this.routesTarget.push(this.routes.find(x => x.RouteId == recover.DestinyRouteId));
    this.routesTarget.sort((a, b) => a.RouteId - b.RouteId);
  }

  /**
   * Aplica los traspasos de las rutas seleccionadas
   * @returns 
   */
  save(){
    if(this.transfers.length == 0){
      this.globalService.messageToast('Debe agregar al menos un traspaso.', 'warning');
      return;
    }
    this.saving = true;
    this.multiService.transferRoutes(this.ceveNode.UnitId, this.selectedDate, this.transfers).subscribe(data => {
      this.globalService.messageToast('Traspasos realizados con éxito.', 'success');
      this.saving = false;
    }, (error) => {
      this.globalService.messageToast('Error al realizar los traspasos.', 'error');
      this.saving = false;
    });
  }

  /**
   * Obtiene el equipo a traspasar
   */
  getEqToTransfer(){
    let route = this.routes.find(x => x.RouteId == this.originRoute);
    this.multiService.dataToTransfer(this.originRoute, this.selectedDate).subscribe(data => {
      this.balances = data;
      if(data.length == 0){
        this.globalService.messageToast(`La ruta ${route.RouteCode} no cuenta con equipo a traspasar.`, 'error');
        this.originRoute = 0;
      }
    }, (error) => {
      this.globalService.messageToast(`La ruta ${route.RouteCode} no cuenta con equipo a traspasar.`, 'error');
      this.originRoute = 0;
      console.log(error);
    });
  }
}
