<div class="content-heading">
    <div class="header-container">
        <h3>Ajuste de Equipo Multicanal</h3>
        <small>Cargos de equipo de las rutas</small>
    </div>
</div>

<div class="container-fluid">
  <div class="form-container">
    <div class="row">
        <div class="form-group col col-md-2">   
            <label class="form-label">Fecha</label>
            <input class="form-control" bsDatepicker [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }" name="LabelDate"
                   [(ngModel)]="selectedDate" placeholder="Fecha" disabled />            
        </div>
        <div class="form-group col col-md-2">
            <label class="form-label">Organizaci&oacute;n</label>
            <select title="Org" class="form-control" [(ngModel)]="orgId" (ngModelChange)="changeFilterOrg($event)" required>
                <option [value]="0" selected>TODOS</option>
                <option *ngFor="let unit of org" [value]="unit.UnitId">{{unit.OrgName}}</option>
            </select>
        </div>
        <div class="form-group col col-md-4">
            <label class="form-label">Centro de ventas</label>
            <ceq-unit-autocomplete [loading]="working"
                [unitOrgId]="orgId"
                [listType]="'CEVE'"
                (onUnitClear)="clearOutputNode()"
                (onUnitSelected)="setOutputNode($event)">
            </ceq-unit-autocomplete>
        </div>
    </div>
    <div class="row">
        <div class="form-group col col-md-3">
            <label class="form-label">Canal</label>
            <br>
            <select title="Channel" class="form-control" name="Channels" [(ngModel)]="selectedChannel" (change)="changeChannel()">
            <option value="0">TODOS</option>
            <option *ngFor="let ch of channelList" [value]="ch.ChannelCode">
                {{ch.ChannelName.toUpperCase() }}
            </option>
            </select>
        </div>
        <div class="form-group col col-md-3">
            <label class="form-label">Rutas</label>
            <select title="Route" class="form-control" name="routes" [(ngModel)]="selectedRoute" (change)="cmbRouteChange()" [disabled]="noRoute">
                <option value="0" selected>Todas</option>
                <option *ngFor="let route of routes" [value]="route.RouteId">
                {{route.RouteCode}} - {{route.RouteName}}
            </option>
            </select>
        </div>
        <div class="form-group col col-md-2">
        </div>
    </div>
  </div>
</div>
<app-loading *ngIf="loading"></app-loading>
<div class="container-fluid" *ngIf="!loading">
    <div class="form-container">
        <div class="data-container black-text">
            <div class="d-flex">
                <div class="column_width center-data" *ngFor="let header of cols">
                    <div class="column_width center-data background-nav-color"> {{header}}</div>
                </div>          
            </div>
            <div class="d-flex">
                <div class="row" *ngFor="let header of cols">
                    <div class="col">
                        <div class="column_width center-data" *ngFor="let row of loadData">
                            <input *ngIf="header == 'RUTA'" title="{{row[header]}}" type="text"  class="form-control center-data m-left" value="{{row[header]}}" name="{{row[header]}}" disabled>
                            <input *ngIf="header == 'CANAL'" title="{{row[header]}}" type="text"  class="form-control center-data m-left" value="{{row[header]}}" name="{{row[header]}}" hidden>
                            <input *ngIf="header != 'RUTA' && header != 'CANAL'" title="{{row[header]}}" type="number" class="form-control right-data m-left"  [disabled]="!enableCargo"
                                (change)="putAdjust(row,header,$event.target.value)"  value="{{row[header]}}" name="{{row[header]}}" required max="999" min="0">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-md-12">
                <button class="btn btn-outline-primary mr-1 btn-labeled mb-2" (click)="saveData()" [disabled]="!enableCargo">
                    <span class="btn-label">
                        <i class="fa fa-save" aria-hidden="true" *ngIf="!saving"></i>
                        <i class="fa fa-circle-notch fa-spin" *ngIf="saving"></i>
                    </span>
                    <span>Guardar Ajustes</span>                      
                </button>
            </div>
        </div>
    </div>
</div>  