import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { GridOptions } from 'ag-grid-community';
import { CeqTableComponent } from '../../common/ceq-table/ceq-table.component';
import { MultiselectOption } from '../../common/ceq-multiselect/ceq-multiselect.component';

import { BalanceResume, MultichannelService } from '../multichannel.service';
import { EquipmentloanserviceService } from '../../self-service/equipment-loan/equipmentloanservice.service';
import { CeveByContainerService } from '../../catalog/ceve/ceveByContainer.service';
import { GlobalFunctionsService } from '../../common/functions/global-functions.service';
import { ContainerService } from '../../catalog/container/container/container.service';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { CeveService } from '../../catalog/ceve/ceve.service';

import { Channel } from '../../common/models/Channel.Model';
import { Route } from '../../common/models/Route.Model';
import { Unit } from '../../common/models/Unit.Model';

@Component({
  selector: 'app-route-balance',
  templateUrl: './route-balance.component.html',
  styleUrls: ['./route-balance.component.scss']
})
export class RouteBalanceComponent implements OnInit {

  constructor(
    private multichannelSerice: MultichannelService,
    private settings: SettingsService,
    private globalService: GlobalFunctionsService,
    private equipmentLoanService: EquipmentloanserviceService,
    private containerService: ContainerService,
    private ceveByContainerService: CeveByContainerService,
    private ceveService: CeveService,
  ) { }

  @ViewChild('ceqGrid', { static: false }) ceqGrid: CeqTableComponent;
  ceqListOptions: GridOptions;

  listOptions = {
    context: this,
    // suppressHorizontalScroll: true,
    // onRowDoubleClicked(event: any): any { },
    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="margin-left:10px"></i>',
      sortDescending: '<i class="fa fa-sort-down" style="margin-left:10px"></i>',
      sortUnSort: '<i class="fa fa-sort" style="margin-left:10px"></i>'
    }
  };

  columnDefs = [
    {
      field: 'ContainerId',
      hide: true,
      sort: 'asc',
      suppressSizeToFit: true
    },
    {
      headerName: 'Equipo',
      field: 'ContainerCode',
      cellStyle: { background: '#A0CFEC', color: 'darkblue', 'text-align': 'center', border: '1px solid gray' },
      width: 150,
      editable: false,
      sortable: true,
      unSortIcon: true,
      resizable: true,
      suppressSizeToFit: true,
      pinned: 'left'
    },
    {
      headerName: '+ Inventario inicial',
      field: 'InitialBalance',
      width: 150,
      cellStyle: { 'text-align': 'end', background: '#C6DEFF', color: 'blue', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: '+ Cargo',
      field: 'Load',
      width: 150,
      cellStyle: { background: '#C6DEFF', color: 'blue', 'text-align': 'end', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: '+ Ajuste de carga',
      field: 'AdjustUp',
      width: 150,
      cellStyle: { background: '#C6DEFF', color: 'blue', 'text-align': 'end', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: '- Ajuste de carga',
      field: 'AdjustDown',
      width: 150,
      cellStyle: { background: '#C6DEFF', color: 'blue', 'text-align': 'end', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: '- Entrega Adelantada',
      field: 'EarlyDelivery',
      width: 150,
      cellStyle: { background: '#C6DEFF', color: 'blue', 'text-align': 'end', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: '- Devolución',
      field: 'DeliveryToReceipt',
      width: 150,
      cellStyle: { background: '#C6DEFF', color: 'blue', 'text-align': 'end', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: '- Vale',
      field: 'SellerPaybill',
      width: 150,
      cellStyle: { 'text-align': 'end', background: '#C6DEFF', color: 'blue', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: '+ Recuperación de vale',
      field: 'PaybillRecovery',
      width: 150,
      cellStyle: { 'text-align': 'end', background: '#C6DEFF', color: 'blue', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: '+ Recuperación de vendedor',
      field: 'SellerRecovery',
      width: 150,
      cellStyle: { 'text-align': 'end', background: '#C6DEFF', color: 'blue', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: '- Equipo no controlado',
      field: 'UnControlled',
      width: 150,
      cellStyle: { 'text-align': 'end', background: '#C6DEFF', color: 'blue', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Existencia en camión CEQ',
      field: 'FinalBalance',
      width: 150,
      cellStyle: { background: '#98FF98', color: 'green', 'text-align': 'end', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    // {
    //   headerName: 'Existencia RTM',
    //   field: 'RtmBalance',
    //   width: 150,
    //   cellStyle: { 'text-align': 'end', background: '#FFFFC2', color: 'brown', border: '1px solid gray' },
    //   editable: false,
    //   resizable: true,
    //   suppressSizeToFit: true
    // },
    {
      headerName: 'Cifra de control',
      field: 'ControlBalance',
      width: 150,
      cellStyle: { 'text-align': 'end', background: '#FFFFC2', color: 'brown', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Vales pendientes',
      field: 'PendingPaybill',
      width: 150,
      cellStyle: { 'text-align': 'end', background: '#FDD7E4', color: 'red', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Comodato',
      field: 'EquipmenLoan',
      width: 150,
      cellStyle: { 'text-align': 'end', background: '#FDD7E4', color: 'red', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    },
    {
      headerName: 'Inventario Integrado',
      field: 'IntegratedInventory',
      width: 150,
      cellStyle: { 'text-align': 'end', background: '#FDD7E4', color: 'red', border: '1px solid gray' },
      editable: false,
      resizable: true,
      suppressSizeToFit: true
    }
  ];

  working: boolean = false;
  resume: BalanceResume = new BalanceResume();
  resalta: boolean = false;
  validation: number = 0;
  charging: boolean = false;
  canGetCargo: boolean = false;
  enableCargo: boolean = true;

  //filters
  routeBalanceReportActivitty: boolean = false;
  selectedDate: string;
  currentDate: Date;
  currentSelectedDate: Date;
  maxDate: string;

  selectedUnit: Unit = undefined;
  channels: Channel[] = [];
  channelId = undefined;
  routes: Route[] = [];
  routeId = undefined;
  routeCode: string = undefined;
  containerTypeList: MultiselectOption[] = [];
  selectedcontainers: number[] = this.containerTypeList.map(x => x.Id);
  asChannelCode: string = '561';
  activities: string[];

  listEmiter(gridOptions: GridOptions): void {
    this.ceqListOptions = gridOptions;
    this.ceqGrid.gridOptions = this.ceqListOptions;
    this.ceqGrid.gridOptions.api.hideOverlay();
  }

  ngOnInit(): void {
    let userActivities = this.settings.getUserAccess();
    this.routeBalanceReportActivitty = userActivities.includes('REPMULTICH');
    this.selectedDate = this.globalService.getStringDate(new Date(), false, 0);
    this.maxDate = this.globalService.getStringDate(new Date(), true, -2);
    this.currentDate = new Date(new Date().setHours(0, 0, 0, 0));
    this.currentSelectedDate = moment(this.selectedDate).toDate();
    this.activities = this.settings.getUserAccess();
    console.log(this.currentSelectedDate, this.currentDate);
    if(this.activities.includes("GETCARGO")){
      if(moment(this.currentSelectedDate).isSame(this.currentDate, 'day')){
        this.canGetCargo = true;
      }else{
        this.canGetCargo = false;
      }
    }
  }


  onUnitClear() {
    this.selectedUnit = undefined;
    this.channels = [];
    this.channelId = undefined;
    this.routes = [];
    this.routeId = undefined;
    this.routeCode = undefined;
    this.containerTypeList = [];
    this.selectedcontainers = [];

    this.ceqGrid.data = [];
  }

  async onUnitSelected(event: Unit) {
    this.selectedUnit = event;
    //getContainers must be before getChannelsByCeve to ensure grid dato is displayed when the page is showed
    await this.getContainers();
    await this.GetRouteBalanceResume();
    await this.getChannelsByCeve();
  }

  async onChannelSelected(event: any) {
    await this.getRoutesByChannel();
  }

  async onRouteSelected(event: any) {
    this.routeCode = this.routes.find(x => x.RouteId == this.routeId).RouteCode;
    await this.GetRouteBalance();
  }

  async onDateSelected() {
    console.log(this.currentSelectedDate, this.currentDate);
    this.currentSelectedDate = moment(this.selectedDate).toDate();
    if(moment(this.currentSelectedDate).isSame(this.currentDate, 'day')){
      this.canGetCargo = true;
    }else{
      this.canGetCargo = false;
    }
  this.GetRouteBalanceResume();
    await this.GetRouteBalance();
  }

  async getChannelsByCeve() {
    this.working = true;

    await this.ceveService.getChannelsbyCeve(this.selectedUnit.UnitId)
      .toPromise()
      .then(data => {
        this.working = false;
        this.channels = data.filter(x => x.ChannelCode != this.asChannelCode).map(x => { return { UnitId: x.ChannelId, ChannelCode: x.ChannelCode, ChannelName: x.ChannelName } });
        if (this.channels.length > 0) {
          this.channelId = this.channels[0].UnitId;
          this.onChannelSelected(this.channelId);
        }
      })
      .catch(async (error) => {
        this.working = false;
        console.error(error);
        await this.globalService.messageToast(error.error.Message, 'error');
      });

  }

  async getRoutesByChannel() {
    this.working = true;
    this.equipmentLoanService.getRoutesByChannel(this.selectedUnit.UnitId, this.channelId)
      .toPromise()
      .then(async resp => {
        this.working = false;
        this.routes = resp;
        if (this.routes.length > 0) {
          this.routeId = resp[0].RouteId;
          this.routeCode = resp[0].RouteCode;
          this.GetRouteBalance();
        }
      })
      .catch(async (error) => {
        this.working = false;
        console.error(error);
        await this.globalService.messageToast(error.error.Message, 'error');
      });
  }

  async getContainers() {
    this.working = true;
    await this.containerService.getContainersByContainerOrg(this.settings.getUser().Org, 0, 0)
      .toPromise()
      .then(async resp => {
        this.working = false;
        let tmpContainers = resp.map(x => { return { Checked: false, Id: x.ContainerId, Text: x.ContainerCode } });
        if (resp.length > 0) {
          //Consulto el equipo configurado por defecto
          let initialContainers = await this.getInitialContainers();
          //si no hay equipo configurado por defecto, selecciono todos el equipo
          if (initialContainers.length == 0) {
            initialContainers = tmpContainers.map(x => x.Id);
          }
          initialContainers.forEach(item => {
            let c = tmpContainers.find(x => x.Id == item);
            if (c != undefined && c != null) {
              c.Checked = true;
            }
          })

          this.containerTypeList = tmpContainers;
          this.selectedcontainers = this.containerTypeList.filter(x => x.Checked == true).map(x => x.Id);
        }
      })
      .catch(async error => {
        this.working = false;
        console.error(error);
        await this.globalService.messageToast(error.error.Message, 'error');
      })
  }

  async getInitialContainers(): Promise<number[]> {
    let containerIds: number[] = [];
    await this.ceveByContainerService.getCheckedRouteBalanceContainers(this.selectedUnit.UnitId)
      .toPromise()
      .then(resp => {
        containerIds = resp;
      })
      .catch(err => {
        console.error(err);
      });
    return new Promise(resolve => resolve(containerIds));
  }

  async ContainerSelected() {
    await this.GetRouteBalance();
  }

  /**Se ejecuta cuando se desenfoca la lista desplegable de equipo  */
  async onRemissionTypeFilterChange(event: MultiselectOption[]) {

    this.selectedcontainers = event.map(x => x.Id);
    await this.GetRouteBalance();
  }

  /**se ejecuta cuando se selecciona un equipo de la lista desplegable */
  onRemissionTypeItemCheckedChange(event: MultiselectOption) {
    //
  }


  async GetRouteBalance() {
    //si no hay canales ni rutas seleccionadas, salgo 
    if (this.channelId == null || this.channelId == undefined) {
      return;
    } if (this.routeId == null || this.routeId == undefined) {
      return;
    }


    this.working = true;
    this.ceqGrid.gridOptions.api.showLoadingOverlay();
    await this.multichannelSerice.getRouteBalance(this.selectedUnit.UnitId, this.channelId, this.routeId, this.selectedcontainers, this.selectedDate)
      .toPromise()
      .then(async resp => {
        this.working = false;
        this.ceqGrid.data = resp;
        this.ceqGrid.gridOptions.api.hideOverlay();
      })
      .catch(async error => {
        this.working = false;
        this.ceqGrid.gridOptions.api.hideOverlay();
        console.error(error);
        await this.globalService.messageToast(error.error.Message, 'error');
      });
  }

  async GetRouteBalanceResume() {
    this.working = true;
    await this.multichannelSerice.getRouteBalanceResume(this.selectedUnit.UnitId, this.selectedDate)
      .toPromise()
      .then(async resp => {
        this.resume = resp;

        this.validation = this.resume.inventario_dia_anterior
          + this.resume.cargo
          + this.resume.ajuste_cargo
          - this.resume.devolucion_total
          - this.resume.entrega_adelantada_total
          - this.resume.vale_vendedor
          + this.resume.recuperacion_vale
          + this.resume.recuperacion_vendedor
          - this.resume.no_controlado;

        this.validation = this.validation < 0 ? 0 : this.validation;

        if ((this.validation - this.resume.saldo_final_total) != 0)
          this.resalta = true;
        else
          this.resalta = false;

        this.getLoadData();
        this.working = false;
      })
      .catch(async error => {
        this.working = false;
        console.error(error);
        await this.globalService.messageToast(error.error.Message, 'error');
      });
  }

  // Obtiene los datos de carga de las rutas
  async getLoadData() {
    await this.multichannelSerice.getRouteDataLoad(this.selectedUnit.UnitId, this.selectedDate).toPromise().then(resp => {
      if( resp.Data.length > 0){
        this.enableCargo = resp.HasCargos;
      }else{
        this.enableCargo = false;
        this.globalService.messageToast(`No se encontraron cargos en este ceve.`, 'warning');
      }
    });
  }

  // Se obtienen los cargos de forma manual
  async getCharge(){
    this.charging = true;
    this.multichannelSerice.getChargeRoutes(this.selectedUnit.UnitId, this.selectedDate).subscribe(async resp => {
      console.log(resp);
      if(resp != null && resp != undefined){
        this.charging = false;
        this.enableCargo = true;
        await this.GetRouteBalanceResume();
        await this.getChannelsByCeve();
        this.globalService.messageToast('Datos cargados correctamente', 'success');
      }else{
        this.enableCargo = false;
        this.charging = false;
        this.globalService.messageToast('Aún no están disponibles los cargos.', 'warning');
      }
    }, (error) => {
      this.enableCargo = false;
      this.charging = false;
      this.globalService.messageToast('Error al obtener los cargos', 'error');
    });
  }

}