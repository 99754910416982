<div class="content-heading">
    <div class="header-Container">
        <h3>Saldo de Rutas</h3>
        <small>Rutas Multicanal</small>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <div class="card card-default">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <!-- start filters -->
                            <div class="row">
                                <div class="form-group col-lg-3 col-md-6 col-sm-12">
                                    <label class="form-label">Centro de ventas</label>
                                    <ceq-unit-autocomplete [loading]="working"
                                                           [listType]="'CEVE'"
                                                           (onUnitClear)="onUnitClear()"
                                                           (onUnitSelected)="onUnitSelected($event)">
                                    </ceq-unit-autocomplete>
                                </div>

                                <div class="form-group col-lg-2 col-md-6 col-sm-12">
                                    <label class="form-label">Canal</label>
                                    <select class="form-control"
                                            [(ngModel)]="channelId"
                                            (change)="onChannelSelected($event.target.value)"
                                            [disabled]="working || !selectedUnit">
                                        <option [value]="undefined" disabled *ngIf="channels.length === 0">
                                            Centro de ventas sin canales asignados
                                        </option>
                                        <option *ngFor="let channel of channels" [value]="channel.UnitId">
                                            {{channel.ChannelCode}} - {{channel.ChannelName}}
                                        </option>
                                    </select>
                                </div>

                                <div class="form-group col-lg-2 col-md-6 col-sm-12">
                                    <label class="form-label">Ruta</label>
                                    <select class="form-control"
                                            [(ngModel)]="routeId"
                                            (change)="onRouteSelected($event.target.value)"
                                            [disabled]="working || !selectedUnit">
                                        <option [value]="undefined" disabled *ngIf="routes.length===0">
                                            Canal sin rutas
                                        </option>
                                        <option *ngFor="let route of routes" [value]="route.RouteId">
                                            {{ route.RouteName }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group col-lg-3 col-md-6 col-sm-12">
                                    <label class="form-label">Equipo</label>
                                    <app-ceq-multiselect [Items]="containerTypeList"
                                                         (shareCheckedList)="onRemissionTypeFilterChange($event)"
                                                         (shareIndividualCheckedList)="onRemissionTypeItemCheckedChange($event)"
                                                         [disabled]="working || !selectedUnit"></app-ceq-multiselect>
                                </div>

                                <div class="form-group col-lg-2 col-md-6 col-sm-12">
                                    <label class="form-label">Fecha</label>
                                    <input class="form-control"
                                           type="date"
                                           [(ngModel)]="selectedDate"
                                           max="{{ maxDate }}"
                                           (change)="onDateSelected()"
                                           [disabled]="working || !selectedUnit" />
                                </div>
                            </div>
                            <!-- end filters -->

                            <!-- start cifras de control -->
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 bottom">
                                    <h4>
                                        TOTALES - Todas las rutas Multicanal del Centro de Ventas
                                    </h4>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 right-data">
                                    <button class="btn btn-outline-pink mr-1 btn-labeled mb-2" (click)="getCharge()" [hidden]="!canGetCargo" [disabled]="enableCargo">
                                        <span class="btn-label">
                                            <i class="fa fa-truck" aria-hidden="true" *ngIf="!charging"></i>
                                            <i class="fa fa-circle-notch fa-spin" *ngIf="charging"></i>
                                        </span>
                                        <span>Solicitar Cargos</span>                      
                                    </button>                        
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <table class="table table-striped table-sm" border="1">
                                        <thead class="background-sidebar-color">
                                            <tr>
                                                <th scope="col" class="text-center align-middle">Inventario del d&iacute;a anterior</th>
                                                <th scope="col" class="text-center align-middle">Cargos</th>
                                                <th scope="col" class="text-center align-middle">Ajuste de Carga</th>
                                                <th scope="col" class="text-center align-middle">Entrega Adelantada</th>
                                                <th scope="col" class="text-center align-middle">Devoluci&oacute;n</th>

                                                <th scope="col" class="text-center align-middle">Vales</th>
                                                <th scope="col" class="text-center align-middle">Recuperaci&oacute;n de Vale</th>
                                                <th scope="col" class="text-center align-middle">Recuperaci&oacute;n de Vendedor</th>

                                                <th scope="col" class="text-center align-middle">No controlado</th>
                                                <th scope="col" class="text-center align-middle">Inventario de Ruta</th>
                                                <th scope="col" class="text-center align-middle">Validaci&oacute;n</th>
                                            </tr>
                                        </thead>
                                        <tbody class>
                                            <tr class="">
                                                <!-- inicial -->
                                                <td class="p-0 text-right ">
                                                    <p class="bg-gray mb-0 h-100" *ngIf="working ||  !selectedUnit">{{resume.inventario_dia_anterior }}</p>
                                                    <p class="mb-0" *ngIf="!working && selectedUnit">{{resume.inventario_dia_anterior }}</p>
                                                </td>
                                                <!-- carga -->
                                                <td class="p-0 text-right">
                                                    <p class="bg-gray mb-0 h-100" *ngIf="working || !selectedUnit">{{resume.cargo }}</p>
                                                    <p class="mb-0" *ngIf="!working && selectedUnit">{{resume.cargo }}</p>
                                                </td>
                                                <!-- ajuste de carga -->
                                                <td class="p-0 text-right">
                                                    <p class="bg-gray mb-0 h-100" *ngIf="working || !selectedUnit">{{resume.ajuste_cargo }}</p>
                                                    <p class="mb-0" *ngIf="!working && selectedUnit">{{resume.ajuste_cargo }}</p>
                                                </td>
                                                <!-- entrega adelantada -->
                                                <td class="p-0 text-right">
                                                    <p class="bg-gray mb-0 h-100" *ngIf="working || !selectedUnit">{{resume.entrega_adelantada_total }}</p>
                                                    <p class="mb-0" *ngIf="!working && selectedUnit">{{resume.entrega_adelantada_total }}</p>
                                                </td>
                                                <!-- devolucion  -->
                                                <td class="p-0 text-right">
                                                    <p class="bg-gray mb-0 h-100" *ngIf="working || !selectedUnit">{{resume.devolucion_total }}</p>
                                                    <p class="mb-0" *ngIf="!working && selectedUnit">{{resume.devolucion_total }}</p>
                                                </td>
                                                <!-- vale -->
                                                <td class="p-0 text-right">
                                                    <p class="bg-gray mb-0 h-100" *ngIf="working || !selectedUnit">{{resume.vale_vendedor }}</p>
                                                    <p class="mb-0" *ngIf="!working && selectedUnit">{{resume.vale_vendedor }}</p>
                                                </td>
                                                <!-- recuperacion de vale -->
                                                <td class="p-0 text-right">
                                                    <p class="bg-gray mb-0 h-100" *ngIf="working || !selectedUnit">{{resume.recuperacion_vale }}</p>
                                                    <p class="mb-0" *ngIf="!working && selectedUnit">{{resume.recuperacion_vale }}</p>
                                                </td>
                                                <!-- recuperacion de vendedor -->
                                                <td class="p-0 text-right">
                                                    <p class="bg-gray mb-0 h-100" *ngIf="working ||  !selectedUnit">{{resume.recuperacion_vendedor }}</p>
                                                    <p class="mb-0" *ngIf="!working&& selectedUnit">{{resume.recuperacion_vendedor }}</p>
                                                </td>
                                                <!-- no controlado -->
                                                <td class="p-0 text-right">
                                                    <p class="bg-gray mb-0 h-100" *ngIf="working ||!selectedUnit">{{resume.no_controlado }}</p>
                                                    <p class="mb-0" *ngIf="!working  && selectedUnit">{{resume.no_controlado }}</p>
                                                </td>
                                                <!-- final -->
                                                <td class="p-0 text-right">
                                                    <p class="bg-gray mb-0 h-100" *ngIf="working ||!selectedUnit">{{resume.saldo_final_total }}</p>
                                                    <p class="mb-0" *ngIf="!working  && selectedUnit">{{resume.saldo_final_total }}</p>
                                                </td>
                                                <!-- validacion -->
                                                <td class="p-0 text-right" *ngIf="resalta" bgcolor="red"><p>{{validation}}</p></td>
                                                <td class="p-0 text-right" *ngIf="!resalta"><p>{{validation}}</p></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- end cifras de control -->
                            <br />
                            <!-- start table -->
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <h4 *ngIf="routeId>0">
                                        Ruta: {{ routeCode }}
                                    </h4>
                                    <app-ceq-table [gridAdditionalOptions]="listOptions"
                                                   [columnDef]="columnDefs"
                                                   (apiEmitter)="listEmiter($event)"
                                                   #ceqGrid>
                                    </app-ceq-table>
                                </div>
                            </div>
                            <!-- end table  -->

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<app-route-balance-report *ngIf="routeBalanceReportActivitty"></app-route-balance-report>