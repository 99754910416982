
<div class="content-heading">
    <div class="header-Container">
        <h3>Devoluci&oacute;n de equipo</h3>
        <small>Devoluci&oacute;n en Rutas Multicanal</small>
    </div>
</div>


<div class="container-fluid">
    <div class="card card-default">
        <div class="card-body">
            <!-- inicio filtros -->
            <div class="row">
                <div class="form-group col-lg-4 col-sm-6">
                    <label for="" class="form-label">Fecha</label>
                    <input type="date" class="form-control" [min]="minDate" [max]="maxDate" [value]="selectedDate" disabled>
                </div>

                <div class="form-group col-lg-4 col-sm-6">
                    <label for="" class="form-label">Organizacion</label>
                    <select [(ngModel)]="selectedOrg" class="form-control" [disabled]="loading">
                        <option *ngFor="let o of orgList" [ngValue]="o">{{o.OrgName}}</option>
                    </select>
                </div>

                <div class="form-group col-lg-4 col-sm-6">
                    <label for="" class="form-label">Centro de ventas</label>
                    <ceq-unit-autocomplete [listType]="'CEVE'"
                                           [loading]="loading"
                                           [unitOrgId]="selectedOrg?.UnitId"
                                           (onUnitClear)="onCeveClear()"
                                           (onUnitSelected)="onCeveChanged($event)">
                    </ceq-unit-autocomplete>
                </div>

                <div class="form-group col-lg-4 col-sm-6">
                    <label for="" class="form-label">Canal</label>
                    <select [(ngModel)]="selectedChannel" class="form-control" (ngModelChange)="getRoutesSync()" [disabled]="loading">
                        <option [ngValue]="undefined">Todos</option>
                        <option *ngFor="let c of channelList" [ngValue]="c">{{c.ChannelName}}</option>
                    </select>
                </div>

                <div class="form-group col-lg-4 col-sm-6">
                    <label for="" class="form-label">Ruta</label>
                    <input class="form-control"
                           [disabled]="loading"
                           minlength="0"
                           maxlength="4"
                           onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                           [(ngModel)]="routeCodeTyped"
                           (ngModelChange)="OnRouteTyped()">
                </div>

            </div>
            <!-- fin filtros -->

            <hr> <!-- separador -->
            <app-loading *ngIf="loading"></app-loading>

            <!-- inicio listas de rutas -->
            <div class="row" *ngIf="!loading">
                <!-- inicio rutas sincronizadas -->
                <div class="col-lg-2 col-sm-4 border-right">
                    <h3 class="text-center">Rutas Sincronizadas</h3>
                    <div style="max-height: 500px; overflow: auto;">
                        <table class="table table-striped">
                            <thead class="table-head-sticky background-nav-color">
                                <tr>
                                    <th scope="col" style="width: 100%;" class="text-center">
                                        <span>Ruta</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let r of RoutesSync" class="hover-item-list" (click)="SyncRouteClick(r.RouteId, r.RouteCode)">
                                    <td style="width: 100%;" class="text-center">
                                        <span data-toggle="tooltip" [title]="r.RouteName">{{r.RouteCode}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- fin rutas sincronizadas -->

                <!-- inicio rutas recibidas  -->
                <div class="col-lg-10 col-sm-8">
                    <h3 class="text-center">Rutas Recibidas</h3>


                    <div style=" max-height: 500px;width: 100%; overflow: auto;">
                        <table class="table table-striped m-auto" style="width: max-content;">
                            <thead class="table-head-sticky">
                                <tr class="btn-color-theme">
                                    <th class="table-cell text-center">
                                        <span>Total</span>
                                    </th>
                                    <th class="table-cell text-center" *ngFor="let c of totalContainers">
                                        <span data-toggle="tooltip" [title]="c.ContainerCode">{{c.Total}}</span>
                                    </th>
                                </tr>
                                <tr class="background-nav-color">
                                    <th class="table-cell text-center">
                                        <span>Ruta</span>
                                    </th>
                                    <th class="text-center table-cell" *ngFor="let c of managedContainers">
                                        <span>{{c.ContainerCode}}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let r of RoutesReceived" >
                                    <th class="text-center table-cell">
                                        <span data-toggle="tooltip" [title]="r.RouteName">{{r.RouteCode}}</span>
                                    </th>
                                    <th class="text-center table-cell" *ngFor="let c of r.Containers">
                                        <span>{{c.VanStock}}</span>
                                    </th>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                </div>
                <!-- fin rutas recibidas -->
            </div>
            <!-- fin listas de rutas -->
        </div>
    </div>
</div>
<div class="modal fade" bsModal #acceptModal="bs-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title font-weight-bold" id="exampleModalLongTitle">{{titleModal}}</h3>
                <button type="button"  class="close" aria-label="Close" (click)="acceptModal.hide()">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="table-responsive">
                    <table class="table table-striped table-bordered table-hover equipoClass">
                        <thead>
                        <tr class="background-nav-color">
                            <th scope="col" class="colOrder">
                                <div>
                                    <span>Equipo</span>
                                    <button type="button" class="btn btn-default" [hidden]="typeDatTable[0]" (click)="updateTable(true,'ContainerCode')">
                                        <span class="fa fa-caret-up text-light"></span>
                                    </button>
                                    <button type="button" class="btn btn-default" [hidden]="!typeDatTable[0]" (click)="updateTable(false,'ContainerCode')">
                                        <span class="fa fa-caret-down  text-light"></span>
                                    </button>
                                </div>
                            </th>
                            <th scope="col" class="colOrder">
                                <div>
                                    <span>Devolución</span>
                                    <button type="button" class="btn btn-default" [hidden]="typeDatTable[1]" (click)="updateTable(true,'Unload')">
                                        <span class="fa fa-caret-up text-light"></span>
                                    </button>
                                    <button type="button" class="btn btn-default" [hidden]="!typeDatTable[1]" (click)="updateTable(false,'Unload')">
                                        <span class="fa fa-caret-down text-light"></span>
                                    </button>
                                </div>
                            </th>
                            <th scope="col"class="colOrder">
                                <div>
                                    <span style="height: 28px;">Entregado</span>
                                </div>
                            </th>
                            <th scope="col" class="colOrder">
                                <div>
                                    <span>Diferencia</span>
                                    <button type="button" class="btn btn-default" [hidden]="typeDatTable[2]" (click)="updateTable(true,'Diff')">
                                        <span class="fa fa-caret-up text-light"></span>
                                    </button>
                                    <button type="button" class="btn btn-default" [hidden]="!typeDatTable[2]" (click)="updateTable(false,'Diff')">
                                        <span class="fa fa-caret-down text-light"></span>
                                    </button>
                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let data of ceqGrid; index as i">
                            <td><b>{{data.ContainerCode}}</b></td>
                            <td class="text-right">{{data.Unload}}</td>
                            <td class="text-right">
                                <span *ngIf="!validateModal">{{data.DeliveryToReceipt}}</span>
                                <input *ngIf="validateModal" style="text-align:right;" type="number" maxlength="9" max="{{maxQty}}" min="0" OnlyNumber="true" [id]="'Ident_' + data.ContainerCode" class="form-control input-number" [(ngModel)]="data.VanStock" (ngModelChange)="changeRange($event, data.ContainerCode)" (keyup)="changeInput($event, i)" autocomplete="off" >
                            </td>
                            <td class="text-right">
                                <span *ngIf="!validateModal">{{data.DeliveryToReceipt - data.Unload}}</span>
                                <span *ngIf="validateModal">{{data.Diff}}</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </div>

            <app-loading *ngIf="loadingModal"></app-loading>
            
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="acceptModal.hide()">Cerrar</button>
                <button type="button" class="btn btn-primary" (click)="saveData()" *ngIf="validateModal">Guardar Cambios</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" bsModal #printModal="bs-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title font-weight-bold" id="exampleModalLongTitle">Se genera el siguiente vale:</h3>
                <button type="button"  class="close" aria-label="Close" (click)="printModal.hide()">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-3">
                        <label class="control-label">Fecha de creación:</label>
                    </div>
                    <div class="col-9">
                        <label class="control-label">{{ceqPrintDateC}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="control-label">Fecha de vencimiento:</label>
                    </div>
                    <div class="col-9">
                        <label class="control-label">{{ceqPrintDateV}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="control-label">Ruta:</label>
                    </div>
                    <div class="col-9">
                        <label class="control-label">{{ceqPrintRute}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="control-label">Vendedor:</label>
                    </div>
                    <div class="col-9">
                        <label class="control-label">{{ceqPrintSalesman}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="control-label">Supervisor:</label>
                    </div>
                    <div class="col-9">
                        <label class="control-label">{{ceqPrintSupervisor}}</label>
                    </div>
                </div>
                <table class="table table-sm">
                    <thead>
                        <tr class="table-danger">
                            <th scope="col">Equipo</th>
                            <th scope="col">Cantidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let info of ceqPrint;">
                            <td>{{info.ContainerCode}}</td>
                            <td>{{info.Diff}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="printModal.hide()">Cerrar</button>
                <button type="button" class="btn btn-primary" (click)="printVale(ceqPrintReference)">Imprimir <span class="fa fa-print"></span></button>
            </div>
        </div>
    </div>
</div>