
<div class="header-container">
  <div class="container-fluid black-text" style="font-size: medium; background-color: white">
    <div class="row">
      <div class="center-data col-sm-12">
        <h2>Vales a cobro</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-7">
        <span class="font-weight-bold">Organización: {{ceve.OrgName}}</span>
      </div>
      <div class="col-sm-7">
        <span class="font-weight-bold">Centro de ventas: {{ ceve.CeveName}}</span>
      </div>
      <div class="col-sm-7">
        <span class="font-weight-bold">Hora y Fecha de impresión: {{ dateDay  }}</span>
      </div>
    </div>


    

    <app-loading *ngIf="loading"></app-loading>
<div class="container-fluid" *ngIf="!loading">
    <div class="form-container">
        <div class="data-container black-text">
            <div class="d-flex">
                <div class="column_width center-data" *ngFor="let header of cols">
                  <div class="column_width center-data background-nav-color"> {{header}}</div>
                </div>          
           </div>
            <div class="d-flex">
                <div class="row" *ngFor="let header of cols">
                    <div class="col">
                        <div class="column_width center-data" *ngFor="let row of loadData">
                         <input *ngIf="header != 'CANAL'" title="{{row[header]}}"  class="form-control right-data m-left" 
                            value="{{row[header]}}" name="{{row[header]}}"disabled>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </div>
    </div>

    <!-- <br />
   
        <div>
          <app-ceq-table #CeqGrid [gridAdditionalOptions]="gridCeqOptions" [columnDef]="columnDefs" (apiEmitter)="gridEmiter($event)">            
          </app-ceq-table>
          
        </div> -->
 
    <br />
    <div class="row" >
      <div class="col-sm-6" style="text-align: center">
        <span class="font-weight-bold">____________________________________</span>
        <br>
        <span class="font-weight-bold" >Nombre de quien entrega</span>
        <br>
        <span class="font-weight-bold " >Personal del área de Almacenes CV's</span>
        <br>
        <br>
        <span class="font-weight-bold">____________________________________</span>
        <br>
        <span class="font-weight-bold">Firma</span>
      </div>

      <div class="col-sm-4" style="text-align: center">
        <span class="font-weight-bold">____________________________________</span>
        <br>
        <span class="font-weight-bold ">Nombre de quien recibe</span>
        <br>
        <span class="font-weight-bold" >Personal del área de Control</span>
        <br>
        <br>
        <span class="font-weight-bold">____________________________________</span>
        <br>
        <span class="font-weight-bold" >  Firma</span>
      </div>
    </div>
    <br />
    <br />
    <div class="buttons-container">
      <button type="button" class="btn btn-labeled btn-outline-danger mb-2" (click)="backClick()">
        <span class="btn-label"><i class="fa fa-chevron-circle-left" aria-hidden="true"></i></span>
        <span>Salir</span>          
      </button>&nbsp;&nbsp;
      <button class="btn btn-labeled btn-outline-primary mb-2" (click)="print()">
        <span class="btn-label"><i class="fa fa-print" aria-hidden="true"></i></span>
        <span>Imprimir</span>
      </button>
    </div>
  </div>
</div>


   
 